import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import FormItem from '../../components/FormItem';
import CustomCheckbox from '../../components/CustomCheckbox';
import FileUploadComponent from './FileUploadComponent';

import css from './styles.css';
import { updateEvent, uploadFile } from '../api';
import RequestStatusModal from '../RequestStatusModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Regulations = () => {
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const [updateSucces, setUpdateSucces] = React.useState(null);

  const history = useHistory();

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    watch,
  } = useForm({
    values: formData.regulations,
  });

  const listingTitle = formData.generalInfo.eventTitle;

  const onSubmit = async data => {
    updateFormData(data, 'regulations');

    const fileId = data.pdfFile && (await uploadFile(data.pdfFile[0]));

    const updateEventPayload = {
      id: listingId,
      hidden: data.isPrivate,
      publicData: {
        returns_policy: data.cancel,
        // listing_hide: data.isPrivate,
        qrEnabled: data.qrEnabled,
      },
      termsFileId: fileId,
      // state: isLastStep ? 'published' : 'draft',
    };

    if (isLastStep) {
      updateEventPayload.state = 'published';
    }

    const updateStatus = await updateEvent(updateEventPayload);
    updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');

    if (type !== 'edit' && isLastStep && updateStatus === 'success') {
      history.push(`/l/${listingTitle}/${listingId}`);
    }
  };

  const submitButtonLabel = useMemo(
    () => (isLastStep && type !== 'edit' ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.regulationsContainer}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}

      <h1 className={css.header}>
        <LeftArrowIcon />
        Zasady i regulamin
      </h1>

      <FormItem label="Zasady zwrotu i anulowania">
        <textarea
          placeholder="Opisz najważniejsze zasady dotyczące wydarzenia"
          rows={3}
          className={css.textarea}
          {...register('cancel', { required: true })}
        />
        {errors.cancel && errors.cancel.type === 'required' && (
          <p style={{ color: '#f00', margin: 0 }} role="alert">
            Zasady zwrotu są wymagane
          </p>
        )}
      </FormItem>

      <FormItem label="Dodaj regulamin">
        <FileUploadComponent
          control={control}
          setValue={setValue}
          watch={watch}
          uploadfile={formData.regulations && formData.regulations.pdfFileUrl}
        />
      </FormItem>

      <FormItem label="Dodatkowe ustawienia">
        {/* <CustomCheckbox label={'Włącz potwierdzanie obecności za pomocą QR-kodów'} control={control} name="qrEnabled" />
                <p className={css.textField}>Każdy zapis będzie opatrzony o QR kod, którym potwierdzisz obecność uczestników</p> */}
        <CustomCheckbox
          label={'Ustaw wydarzenie jako prywatne'}
          control={control}
          name="isPrivate"
        />
        <p className={css.textField}>
          Wydarzenie będzie widoczne tylko dla osób, którym wyślesz link
        </p>
      </FormItem>

      <div className={css.buttonContainer}>
        <button type="submit" className={css.submitClasses}>
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default Regulations;
