import React, { useState } from 'react';
import css from './styles.css';
import Buttons from '../Buttons/Buttons';

import { createFormElement } from '../utils';

const TextAreaField = ({
  setSelectedTypeOfQuestion,
  setIsOpenAddQuestion,
  setStep,
  setFormElements,
  elementData,
}) => {
  const [title, setTitle] = useState((elementData && elementData.title) || '');
  const [description, setDescription] = useState((elementData && elementData.description) || '');
  const [invalidValueTitle, setInvalidValueTitle] = useState(null);
  const [invalidValueTitleDescription, setInvalidValueDescription] = useState(null);
  const [isValidateError, setIsValidateError] = useState(false);

  const handleCancel = () => {
    setIsOpenAddQuestion(false);
    setSelectedTypeOfQuestion(null);
    setStep(null);
  };

  const handleTitleonChange = e => {
    if (e.target.value.trim().length === 0) {
      setTitle(e.target.value);
      setInvalidValueTitle('pole wymagane');
    } else if (e.target.value.length > 300) {
      setInvalidValueTitle('maksymalna długość to 300 znaków');
    } else {
      setTitle(e.target.value);
    }
  };

  const handleDescriptionChange = e => {
    if (e.target.value.trim().length === 0) {
      setDescription(e.target.value);
      setInvalidValueDescription('pole wymagane');
    } else if (e.target.value.length > 300) {
      setInvalidValueDescription('maksymalna długość to 300 znaków');
    } else {
      setDescription(e.target.value);
    }
  };

  const hadnleCreateElement = () => {
    const newelementData = {
      title,
      description,
      selectedTypeOfQuestion: 'textareaField',
    };

    const element = createFormElement(newelementData);

    const elementToSave = {
      id: title,
      elementData: newelementData,
      element,
    };

    if (title && description) {
      setIsValidateError(false);
      setFormElements(prev => {
        if (elementData && elementData.title) {
          let elementToUpdate = prev.findIndex(el => el.id === elementData.title);
          const copy = prev;
          copy[elementToUpdate] = elementToSave;
          return copy;
        } else {
          return [...prev, elementToSave];
        }
      });
      setIsOpenAddQuestion(false);
      setSelectedTypeOfQuestion(null);
      setStep(null);
    } else {
      setIsValidateError(true);
    }
  };

  return (
    <>
      <div className={css.curtain}></div>

      <div className={css.container}>
        <h2 className={css.header}>
          Dostosuj pole tekstowe
          <span style={{ fontWeight: '400' }} onClick={handleCancel}>
            x
          </span>
        </h2>
        <label className={css.label}>
          <p>
            Nadaj tytuł (przykład: Nazwa drużyny)
            <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
          </p>
          <input
            className={css.input}
            type="text"
            value={title}
            onChange={handleTitleonChange}
          ></input>
          {invalidValueTitle && <span>{invalidValueTitle}</span>}
        </label>
        <label className={css.label}>
          <p>
            Podaj opis (przykład: Podaj nazwę Swojej drużyny)
            <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
          </p>
          <input
            className={css.input}
            type="text"
            value={description}
            onChange={handleDescriptionChange}
          ></input>
          {invalidValueTitleDescription && <span>{invalidValueTitleDescription}</span>}
        </label>
        {isValidateError && <p style={{ color: 'red' }}>Uzupełnij wymagane pola</p>}
        <Buttons
          // setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          // setIsOpenAddQuestion={setIsOpenAddQuestion}
          // setStep={setStep}
          hadnleCreateSelectElement={hadnleCreateElement}
          isEdited={!!elementData}
        />
      </div>
    </>
  );
};

export default TextAreaField;
