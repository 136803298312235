import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/addEventForm/leftArrowIcon.svg';
import css from '../styles.css';
import { formatMoney } from '../../../../../util/currency';
import classNames from 'classnames';
import { MinusIcon, PlusIcon } from '../../../../../components/Footer/FooterV2';

const Summary = props => {
  const [transaction, setTransaction] = useState(null);
  useEffect(() => {
    async function fetchTransactionSpeculatively() {
      const fn = props.speculateFn();
      const tx = await fn();
      setTransaction(tx.data);
    };
    fetchTransactionSpeculatively();
  }, []);

  if (!transaction) {
    return <div>Loading...</div>;
  }

  const serviceProvision = transaction.attributes.lineItems.find(item => item.code === 'service-provision');
  const seats = transaction.attributes.lineItems.find(item => item.code === 'booking');
  const reducedSeats = transaction.attributes.lineItems.find(item => item.code === 'booking-reduced');

  const totalBeforeProvision = (seats ? seats.lineTotal.amount : 0) + (reducedSeats ? reducedSeats.lineTotal.amount : 0);

  const metaSeats = (transaction.attributes.metadata && transaction.attributes.metadata.seats) ? transaction.attributes.metadata.seats : [];
  const standardTickets = metaSeats.filter(seat => seat.type === 'standard');
  const reducedTickets = metaSeats.filter(seat => seat.type === 'reduced');

  return (
    <div className={css.formWrapper}>
      <div>
        <div className={classNames(css.summaryWrapper, css.summaryHeader)}>
          <h2 onClick={props.handleBackClick}><LeftArrowIcon />Podsumowanie</h2>
        </div>
        <div className={css.infoBlock}>
          <span className={css.label}>Wydarzenie:</span>
          <span className={css.desc}>{props.listingTitle}<br />
            {/* Niedziela, Luty 24, 18:00<br />
            Bramki otwierają się 0 16:00 */}
          </span>
        </div>
        {
          standardTickets.length > 0 && (
            <div className={css.infoBlock}>
              <span className={css.label}>Bilet normalny</span>
              {
                standardTickets.map((ticket) => (
                  <p key={ticket.label} className={classNames(css.desc, css.ticketDetails)}>{ticket.label}</p>
                ))
              }
            </div>
          )
        }
        {
          reducedTickets.length > 0 && (
            <div className={css.infoBlock}>
              <span className={css.label}>Bilet ulgowy</span>
              {
                reducedTickets.map((ticket) => (
                  <p key={ticket.label} className={classNames(css.desc, css.ticketDetails)}>{ticket.label}</p>
                ))
              }
            </div>
          )
        }
      </div>
      <div>
        <Toggle wrapperClassName={classNames(css.gap24, css.infoBlock)} trigger={<span className={css.label}>Dodaj kod rabatowy <span>(Opcjonalnie)</span></span>}>
          <span className={css.label2}>Wprowadź lub wklej kod rabatowy</span>
          <input type="text" className={css.discountInput} />
        </Toggle>
        <span className={css.label}>Podsumowanie</span>
        {
          !!seats && (
            <div className={classNames(css.summaryRow, css.desc)}>
              <span>{seats.quantity} x Bilet normalny</span>
              <span>{formatMoney(props.intl, seats.lineTotal)}</span>
            </div>
          )
        }
        {
          !!reducedSeats && (
            <div className={classNames(css.summaryRow, css.desc)}>
              <span>{reducedSeats.quantity} x Bilet ulgowy</span>
              <span>{formatMoney(props.intl, reducedSeats.lineTotal)}</span>
            </div>
          )
        }
        <div className={classNames(css.summaryRow, css.desc)}>
          <span>Suma</span>
          <span>{formatMoney(props.intl, { amount: totalBeforeProvision, currency: 'PLN' })}</span>
        </div>
        {
          serviceProvision && (
            <div className={classNames(css.summaryRow, css.desc)}>
              <span>Opłata serwisowa</span>
              <span>{formatMoney(props.intl, serviceProvision.lineTotal)}</span>
            </div>
          )
        }

        <div className={css.divider}></div>
        <div className={css.summaryRow}>
          <span className={css.summaryTitle}>Do zapłaty</span>
          <span className={css.summaryPrice}>{formatMoney(props.intl, transaction.attributes.payinTotal)}</span>
        </div>
        <button
          className={css.summaryConfirmButton}
          onClick={props.handleSubmitClick}
        >
          Przejdź do płatności ({`${formatMoney(props.intl, transaction.attributes.payinTotal)}`})
        </button>
      </div>
    </div>
  );
};


export default injectIntl(Summary);

function Toggle({ trigger, children, wrapperClassName }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={wrapperClassName}>
      <div onClick={() => setIsOpen(!isOpen)} className={css.toggleTrigger}>
        {trigger}
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      {isOpen && children}
    </div>
  );
}