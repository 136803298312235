import React, { useEffect, useState } from 'react';
import { useGoogleMaps } from '../../util/mapContext';

const libraries = ['places'];

const AddressFromCoordinates = ({ lat, lng, location }) => {
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');

  const { address: formatedAddress, building, forcedAddress } = location || {};

  const { isLoaded, loadError } = useGoogleMaps();

  useEffect(() => {
    if (formatedAddress || building || forcedAddress) {
      setAddress(forcedAddress ? 'Polska' : `${building || ''} ${formatedAddress || ''}`);
    }
  }, [location]);

  useEffect(() => {
    if (!isLoaded) return;
    if (formatedAddress || building || forcedAddress) return;

    try {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat, lng };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            setAddress(results[0].formatted_address);
          } else {
            setError('No results found');
          }
        } else {
          setError('Geocoder failed due to: ' + status);
        }
      });
    } catch (error) {
      console.error('Geocoder error: ', error);
      setError('An error occurred during geocoding.');
    }
  }, [isLoaded, lat, lng, location]);

  if (loadError) {
    console.error('Load error: ', loadError);
    return <div>Błąd podczas wczytywania mapy</div>;
  }

  return error ? <div>{error}</div> : <span>{address}</span>;
};

export default AddressFromCoordinates;
