import React, { useEffect, useRef, useState } from 'react';

// import css from './styles.css';
// import { useMutation, useQuery } from 'react-query';
// import { useHistory, useLocation } from 'react-router-dom';
// import RequestStatusModal from '../../../../forms/AddEventForm/RequestStatusModal';
// import AdditionalForm from '../../../../forms/EventParticipantForm/AdditionalForm';
// import ForWhoForm from '../../../../forms/EventParticipantForm/ForWhoForm';
// import { apiSdk } from '../../../../queries/api';
// import ItemList from './ItemList';
// import Payment from './Payment/Payment';
// import SelectTicketsAmount from './SelectTicketsAmount/SelectTicketsAmount';
// import SidePanelContainer from './SidePanelContainer';
// import { addMinutes, formatDate, formatTime } from './utils';
import BookingPanelEvent from './BookingPanelEvent';
import BookingPanelTicketing from './BookingPanelTicketing';

// const useScroll = () => {
//   const initialTopValue = 160;
//   const scrollThreshold = 100;
//   const [stickyTop, setStickyTop] = useState(initialTopValue);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > scrollThreshold) {
//         const newTop = 60;
//         setStickyTop(newTop);
//       } else {
//         setStickyTop(initialTopValue);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return stickyTop;
// };

const BookingPanel = ({ dates, data, isEvent, availableSeats }) => {
  if (isEvent) {
    return <BookingPanelEvent dates={dates} data={data} />;
  } else {
    return <BookingPanelTicketing dates={dates} data={data} availableSeats={availableSeats}/>;
  }
};

// const BookingPanel = ({ dates, data, isEvent }) => {
//   const history = useHistory();
//   const location = useLocation();
//   const isClosed = data.attributes.state === 'closed';

//   const isWithoutActiveDates =
//     !dates.length ||
//     dates.every(date => {
//       const eventDate = new Date(date.attributes.date);
//       eventDate.setMinutes(eventDate.getMinutes() + date.attributes.duration);
//       return eventDate < new Date();
//     });

//   const sts_run = data.attributes.stsLink;
//   const stsDate = sts_run
//     ? !!dates.filter(item => new Date(item.attributes.date) > new Date()).length &&
//       dates.filter(item => new Date(item.attributes.date) > new Date())[0].id.uuid
//     : null;

//   const priceHide = data.attributes.publicData.price_hide;

//   const searchParams = new URLSearchParams(location.search);
//   const isEditMode = searchParams.get('mode');

//   const isForChildren =
//     !!data.attributes.publicData.gender && !!data.attributes.publicData.gender.includes('children');
//   const isMyEvent = data.attributes.isMy;

//   const [isFormOpen, setIsFormOpen] = useState(false);
//   const [isAdditionalFormOpen, setIsAdditionalFormOpen] = useState(false);
//   const [selectedDateId, setSelectedDateId] = useState(null);
//   const [participantsData, setParticipantsData] = useState([]);
//   const [additionalFormData, setAdditionalFormData] = useState([]);
//   const [hasAdditionalFormOpen, setHasAdditionalFormOpen] = useState(
//     !!data.attributes.publicData.additionalForm &&
//       !!data.attributes.publicData.additionalForm.length
//   );
//   const [participantsLimit, setPaticipantsLimit] = useState();
//   const [isAllBooked, setIsAllBooked] = useState(false);
//   const [isOpenPaymentForm, setIsOpenPaymentForm] = useState(false);
//   const [transactionData, setTransactionData] = useState({});
//   const [forwardButtonDisabled, setForwardButtonDisabled] = useState(false);
//   const [transactionError, setTransactionError] = useState(null);
//   const [isSelectTicketsAmountOpen, setIsSelectTicketsAmountOpen] = useState(false);
//   const [hasReducedPrice] = useState(
//     !!data.attributes.reducedPrice && !!data.attributes.reducedPrice.amount
//   );
//   const [seats, setSeats] = useState(0);
//   const [reducedSeats, setReducedSeats] = useState(0);

//   const { data: fetchedUSerData, isSuccess } = useQuery('user.current', () =>
//     apiSdk.users.currentUser()
//   );

//   const [userData, setUserData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//   });

//   const [isNoUser, setIsNoUSer] = useState(true);
//   const [isAuthenticated] = useState(!!localStorage.getItem('go-sdk-jwt'));

//   const stickyTop = useScroll();

//   const activeDates = dates
//     .filter(date => {
//       const eventDate = new Date(date.attributes.date);
//       eventDate.setMinutes(eventDate.getMinutes() + date.attributes.duration);
//       return (
//         eventDate > new Date() &&
//         (date.attributes.participantsLimit - date.attributes.participantsCount > 0 ||
//           date.attributes.participantsLimit === null)
//       );
//     })
//     .sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));

//   const [, setIsClickedOutsideSIdePanel] = useState(false);

//   const dropdownRef = useRef(null);

//   const handleClose = () => {
//     setIsFormOpen(false);
//     setAdditionalFormData([]);
//     setIsAdditionalFormOpen(false);
//     setIsOpenPaymentForm(false);
//     setIsSelectTicketsAmountOpen(false);
//     setSelectedDateId(null);
//     setParticipantsData([]);
//     // setCustomerData({});
//     setSeats(0);
//     setReducedSeats(0);
//     setForwardButtonDisabled(false);
//     history.push(`${location.pathname}`);
//   };

//   const handleCloseSidePanel = event => {
//     if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
//       setIsClickedOutsideSIdePanel(false);
//     } else {
//       handleClose();
//     }
//   };

//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const step = query.get('step');
//     const dateId = query.get('dateId');

//     if (step === 'form') {
//       setIsFormOpen(true);
//       setSelectedDateId(dateId);
//     } else if (step === 'additionalForm') {
//       setIsAdditionalFormOpen(true);
//     } else if (step === 'selectTickets') {
//       setIsSelectTicketsAmountOpen(true);
//     } else if (step === 'payment') {
//       setIsOpenPaymentForm(true);
//     }
//   }, [location.search]);

//   useEffect(() => {
//     setUserData({
//       firstName: isSuccess ? fetchedUSerData.data.attributes.profile.firstName : '',
//       lastName: isSuccess ? fetchedUSerData.data.attributes.profile.lastName : '',
//       email: isSuccess ? fetchedUSerData.data.attributes.email : '',
//     });
//   }, [isSuccess]);

//   useEffect(() => {
//     setIsNoUSer(!userData.firstName || !userData.lastName);
//   }, [userData]);

//   useEffect(() => {
//     if (participantsData.filter(participant => participant.isChecked).length > participantsLimit) {
//       setIsAllBooked(true);
//     } else {
//       setIsAllBooked(false);
//     }
//   }, [participantsData, participantsLimit]);

//   const mutation = useMutation(apiSdk.transactions.create, {
//     onSuccess: data => {
//       setTransactionData(data);

//       if (
//         data.data.attributes.processState === 'pending-payment' &&
//         !!data.data.attributes.stripePaymentIntent
//       ) {
//         setIsFormOpen(false);
//         setIsAdditionalFormOpen(false);
//         setIsSelectTicketsAmountOpen(false);
//         setIsOpenPaymentForm(true);
//       } else {
//         history.push(`/order/${data.data.id.uuid}/details-success`);
//       }
//     },
//     onError: error => {
//       setTransactionError('Jesteś już zapisany na to wydarzenie w wybranym terminie');
//       console.error('error', error);
//     },
//   });

//   const handleSelectDate = date => {
//     if (isAuthenticated) {
//       setIsFormOpen(true);
//       setSelectedDateId(date.id.uuid);
//       setPaticipantsLimit(
//         date.attributes.participantsLimit === null
//           ? 1000000
//           : date.attributes.participantsLimit - date.attributes.participantsCount
//       );
//       history.push(`${location.pathname}?step=form&dateId=${date.id.uuid}`);
//     } else {
//       const location = history.location.pathname;
//       localStorage.setItem('redirectUrl', `${location}?step=form&dateId=${date.id.uuid}`);
//       history.push('/login');
//     }
//   };

//   const handleSignUp = (e, isAdditionalFormComplete) => {
//     if (isAuthenticated) {
//       if (hasAdditionalFormOpen && !isAdditionalFormComplete) {
//         setIsFormOpen(false);
//         setIsAdditionalFormOpen(true);
//         history.push(`${location.pathname}?step=additionalForm&dateId=${selectedDateId}`);
//       } else if (hasReducedPrice && !seats && !reducedSeats) {
//         setIsFormOpen(false);
//         setIsAdditionalFormOpen(false);
//         setIsSelectTicketsAmountOpen(true);
//         history.push(`${location.pathname}?step=selectTickets&dateId=${selectedDateId}`);
//       } else {
//         const transactionData = {
//           listingDateId: selectedDateId,
//           participants: participantsData.map(participant => {
//             return {
//               name: `${participant.firstName} ${participant.lastName}`,
//               age: participant.age || null,
//             };
//           }),
//           seats: !seats && !reducedSeats ? participantsData.length : seats,
//           reducedSeats,
//           metadata: {
//             additionalFormData: additionalFormData || null,
//           },
//         };
//         if (!isAllBooked) {
//           mutation.mutate(transactionData);
//         }
//       }
//     } else {
//       const location = history.location.pathname;
//       localStorage.setItem('redirectUrl', `${location}?step=form&dateId=${selectedDateId}`);
//       history.push('/login');
//     }
//   };

//   useEffect(() => {
//     if (additionalFormData.length === participantsData.length && additionalFormData.length > 0) {
//       handleSignUp(null, true);
//     }
//   }, [additionalFormData.length, participantsData.length]);

//   if (isClosed) {
//     return (
//       <div className={css.container} style={{ top: stickyTop }}>
//         <h2>Rezerwuj</h2>
//         <p className={css.priceInfo}>Brak dostępnych terminów</p>
//       </div>
//     );
//   }

//   if (sts_run) {
//     return (
//       <div className={css.container} style={{ top: stickyTop }}>
//         <h2>Rezerwuj online</h2>
//         <p className={css.priceInfo}>
//           Po zapisaniu się, przejdziesz do uzupełnienia wymaganych danych do startu w zawodach.
//         </p>
//         <p style={{ margin: 0 }}>Liczba dostępnych miejsc:</p>
//         <p style={{ margin: 0 }}>ograniczona</p>
//         <div className={css.date} style={{ border: 'none' }}>
//           <a
//             href={`${sts_run}?imie=${userData.firstName}&nazwisko=${userData.lastName}&email=${userData.email}&go=1`}
//           >
//             <button onClick={() => mutation.mutate({ listingDateId: stsDate })}>Zapisz się</button>
//           </a>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className={css.container} style={{ top: stickyTop }}>
//       {!!transactionError && (
//         <RequestStatusModal
//           status={!transactionError ? 'success' : 'error'}
//           infoError={transactionError}
//           resetStatus={() => setTransactionError(null)}
//         />
//       )}
//       <h2>Rezerwacja</h2>
//       {!priceHide && (
//         <>
//           {!!data.attributes.price && !!data.attributes.price.amount ? (
//             data.attributes.paymentEnabled ? (
//               <div>
//                 <p className={css.priceAmount}>{`${(
//                   (data.attributes.price.amount / 100) * 1.05 +
//                   2
//                 ).toFixed(2)} zł za osobę`}</p>
//               </div>
//             ) : (
//               <div className={css.price}>
//                 <p className={css.priceAmount}>{`${data.attributes.price.amount /
//                   100} zł za osobę`}</p>
//                 <p className={css.priceInfo}>Opłaty dokonasz na miejscu u organizatora</p>
//               </div>
//             )
//           ) : (
//             <div className={css.price}>
//               <p className={css.priceAmount}>Za darmo</p>
//             </div>
//           )}
//         </>
//       )}
//       {isWithoutActiveDates && <p style={{ color: 'red' }}>Brak dostępnych terminów</p>}
//       <ItemList
//         items={
//           activeDates.length
//             ? activeDates.map((date, index) => {
//                 const startTime = new Date(date.attributes.date);
//                 const endTime = addMinutes(startTime, date.attributes.duration);
//                 return (
//                   <div key={index} className={css.date}>
//                     <div>
//                       <h3>{formatDate(startTime)}</h3>
//                       <p>{`${formatTime(startTime)}–${formatTime(endTime)}`}</p>
//                       {date.attributes.participantsLimit === 50000 ||
//                       date.attributes.participantsLimit === null ? (
//                         <p className={css.limit}>Bez limitu miejsc</p>
//                       ) : (
//                         <p className={css.limit}>
//                           Pozostalo{' '}
//                           {date.attributes.participantsLimit - date.attributes.participantsCount} z{' '}
//                           {date.attributes.participantsLimit} miejsc
//                         </p>
//                       )}
//                     </div>
//                     <button disabled={isMyEvent} onClick={() => handleSelectDate(date)}>
//                       Wybierz
//                     </button>
//                   </div>
//                 );
//               })
//             : [<p>Brak dostępnych miejsc</p>]
//         }
//       />

//       <div onClick={handleCloseSidePanel} className={css.date} style={{ border: 'none' }}>
//         {(isFormOpen || isSelectTicketsAmountOpen || isAdditionalFormOpen || isOpenPaymentForm) && (
//           <SidePanelContainer ref={dropdownRef}>
//             {isFormOpen && (
//               <ForWhoForm
//                 participantsData={participantsData}
//                 setParticipantsData={setParticipantsData}
//                 isForChildren={isForChildren}
//                 userData={userData}
//                 setUserData={setUserData}
//                 isAllBooked={isAllBooked}
//                 setForwardButtonDisabled={setForwardButtonDisabled}
//                 handleClose={handleClose}
//               />
//             )}

//             {isAdditionalFormOpen && (
//               <AdditionalForm
//                 data={data.attributes.publicData.additionalForm}
//                 participantsData={participantsData}
//                 additionalFormData={additionalFormData}
//                 setAdditionalFormData={setAdditionalFormData}
//                 handleClose={handleClose}
//                 handleBack={() => {
//                   setIsFormOpen(true);
//                   setIsAdditionalFormOpen(false);
//                   setIsAllBooked(false);
//                   setAdditionalFormData([]);
//                   const searchParams = new URLSearchParams(location.search);
//                   searchParams.set('step', 'form');
//                   searchParams.set('mode', '');
//                   history.push({
//                     pathname: location.pathname,
//                     search: searchParams.toString(),
//                   });
//                 }}
//                 setHasAdditionalFormOpen={setHasAdditionalFormOpen}
//                 hasAdditionalFormOpen={hasAdditionalFormOpen}
//               />
//             )}

//             {isSelectTicketsAmountOpen && (
//               <SelectTicketsAmount
//                 participantsData={participantsData}
//                 reducedSeats={reducedSeats}
//                 setReducedSeats={setReducedSeats}
//                 seats={seats}
//                 setSeats={setSeats}
//                 priceData={{
//                   unitPrice: data.attributes.price ? data.attributes.price.amount : 0,
//                   reducedUnitPrice: data.attributes.reducedPrice
//                     ? data.attributes.reducedPrice.amount
//                     : 0,
//                 }}
//                 setForwardButtonDisabled={setForwardButtonDisabled}
//                 handleClose={handleClose}
//                 handleBack={() => {
//                   setIsSelectTicketsAmountOpen(false);
//                   setHasAdditionalFormOpen(!!data.attributes.publicData.additionalForm.length);
//                   if (!!data.attributes.publicData.additionalForm.length) {
//                     setIsFormOpen(false);
//                     setIsAdditionalFormOpen(true);
//                     setSeats(0);
//                     setReducedSeats(0);
//                     history.push(
//                       `${location.pathname}?step=additionalForm&dateId=${selectedDateId}&mode=edit`
//                     );
//                   } else {
//                     setIsFormOpen(true);
//                     setIsAdditionalFormOpen(false);
//                     setSeats(0);
//                     setReducedSeats(0);
//                     history.push(`${location.pathname}?step=form&dateId=${selectedDateId}`);
//                   }
//                 }}
//               />
//             )}

//             {isOpenPaymentForm && (
//               <Payment
//                 data={transactionData}
//                 handleClose={handleClose}
//                 handleBack={() => {
//                   setIsOpenPaymentForm(false);
//                 }}
//               />
//             )}
//             {(!isAdditionalFormOpen ||
//               (additionalFormData.length === participantsData.length && !isEditMode)) &&
//               !isOpenPaymentForm && (
//                 <button
//                   disabled={isNoUser || isAllBooked || forwardButtonDisabled}
//                   style={{ width: '100%', marginTop: '20px' }}
//                   onClick={handleSignUp}
//                 >
//                   {`${hasAdditionalFormOpen ? 'Przejdź dalej' : 'Zapisz się'}`}
//                 </button>
//               )}
//           </SidePanelContainer>
//         )}
//       </div>
//     </div>
//   );
// };

export default BookingPanel;
