import classNames from 'classnames';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Logo, NamedLink } from '../../components';

import { useIsMobile } from '../BookingPanel/BookingPanel';
import css from './Footer.css';

const FooterV2 = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.rootv2, className);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.linksTop}>
          <div id="organization">
            <NamedLink name="LandingPage" className={css.logoLink}>
              <Logo format="desktop" className={css.logoV2} />
            </NamedLink>
            <div className={css.organizationInfo}>
              <p className={css.organizationDescriptionV2}>
                <FormattedMessage id="Footer.organizationDescriptionV2" />
              </p>
            </div>
          </div>
          <div className={css.linksGridWrapper}>
            <ListWithToggle title="Organizatorzy">
              <li className={css.listItem2}>
                <NamedLink name="NewListingPage" className={css.link}>
                  <FormattedMessage id="Footer.toNewListingPage" />
                </NamedLink>
              </li>
              <li className={css.listItem2}>
                <a
                  className={css.link}
                  href="https://partner.decathlon.pl/poradnik/dla/organizatorow"
                >
                  <FormattedMessage id="Footer.guideForOrganizers" />
                </a>
              </li>
            </ListWithToggle>
            <ListWithToggle title="Kalkulatory">
              <li className={css.listItem2}>
                <NamedLink name="BMICalculator" className={css.link}>
                  Kalkulator BMI
                </NamedLink>
              </li>
              <li className={css.listItem2}>
                <NamedLink name="BMRCalculator" className={css.link}>
                  Kalkulator BMR
                </NamedLink>
              </li>
              <li className={css.listItem2}>
                <NamedLink name="WHRCalculator" className={css.link}>
                  Kalkulator WHR
                </NamedLink>
              </li>
              <li className={css.listItem2}>
                <NamedLink name="RFMCalculator" className={css.link}>
                  Kalkulator RFM
                </NamedLink>
              </li>
            </ListWithToggle>

            {/* <ListWithToggle title="Wydarzenia">
              <li className={css.listItem2}>
                <a className={css.link} href="https://go.decathlon.pl/wydarzenia/bieganie-warszawa">
                  <FormattedMessage id="Footer.WarsawRunning" />
                </a>
              </li>
              <li className={css.listItem2}>
                <a
                  className={css.link}
                  href="https://go.decathlon.pl/wydarzenia/siatkowka-warszawa"
                >
                  <FormattedMessage id="Footer.WarsawVolleyball" />
                </a>
              </li>
              <li className={css.listItem2}>
                <a className={css.link} href="https://go.decathlon.pl/wydarzenia/bieganie-wroclaw">
                  <FormattedMessage id="Footer.WroclawRunning" />
                </a>
              </li>
            </ListWithToggle> */}
          </div>
        </div>
        <div className={css.extraLinksWrapper}>
          <NamedLink name="LandingPage" className={css.legalLink}>
            <FormattedMessage id="Footer.copyrightv2" />
          </NamedLink>
          <div className={css.extraLinksRight}>
            <NamedLink name="LowInformationPage" className={css.terms}>
              <FormattedMessage id="Footer.lowInformation" />
            </NamedLink>
            <NamedLink name="TermsOfServicePage" className={css.legalLink}>
              <FormattedMessage id="Footer.termsOfUse" />
            </NamedLink>
            <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
              <FormattedMessage id="Footer.privacyPolicy" />
            </NamedLink>

            <a target="_blank" href="https://go.decathlon.pl/o-nas" className={css.terms}>
              <FormattedMessage id="Footer.aboutUs" />
            </a>

            <a
              target="_blank"
              href="https://www.decathlon.pl/help/app/dsa-form"
              className={css.terms}
            >
              <FormattedMessage id="Footer.illegalContent" />
            </a>
          </div>
        </div>
        <button className={css.button} onClick={handleScrollTop} type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.4534 5.07472C11.3344 5.12353 11.2229 5.19625 11.1262 5.29289L5.29289 11.1262C4.90237 11.5168 4.90237 12.1499 5.29289 12.5404C5.68342 12.931 6.31658 12.931 6.70711 12.5404L10.8333 8.4142V17.6667C10.8333 18.219 11.2811 18.6667 11.8333 18.6667C12.3856 18.6667 12.8333 18.219 12.8333 17.6667V8.41422L16.9596 12.5404C17.3501 12.931 17.9833 12.931 18.3738 12.5404C18.7643 12.1499 18.7643 11.5168 18.3738 11.1262L12.5431 5.29552C12.5347 5.28708 12.5262 5.27879 12.5175 5.27065C12.3386 5.1028 12.098 5 11.8333 5C11.6988 5 11.5705 5.02656 11.4534 5.07472Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

FooterV2.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterV2.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(FooterV2);

function ListWithToggle({ children, title }) {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(!isMobile);

  const handleClick = () => isMobile && setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);

  return (
    <ul className={css.list}>
      <li
        className={css.listTitle}
        onClick={handleClick}
        {...(isMobile && { style: { cursor: 'pointer', borderTop: '1px solid #DBDFE0' } })}
      >
        <div className={css.listTitleContent}>
          {title}
          {isMobile && (isOpen ? <MinusIcon /> : <PlusIcon />)}
        </div>
      </li>
      <div {...(isMobile && isOpen && { style: { paddingBottom: '20px' } })}>
        {isOpen && children}
      </div>
    </ul>
  );
}

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={css.plusIcon}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.25 11.25V4H12.75V11.25H20V12.75H12.75V20H11.25V12.75H4V11.25H11.25Z"
      fill="#001018"
    />
  </svg>
);

export const MinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={css.minusIcon}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 11.25H20V12.75H4V11.25Z" fill="#001018" />
  </svg>
);
