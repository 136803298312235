import { Autocomplete } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useGoogleMaps } from '../../../util/mapContext';

const LocationInput = ({ apiKey, control, name, initialAddress }) => {
  const { isLoaded, loadError } = useGoogleMaps();

  const { building, address, forcedAddress } = initialAddress;

  const [inputValue, setInputValue] = useState('');
  const autocompleteRef = useRef(null);

  useEffect(() => {
    setInputValue(() => (forcedAddress ? 'Polska' : `${building || ''} ${address || ''}`));
  }, [initialAddress]);

  const handleLoad = autocomplete => {
    autocompleteRef.current = autocomplete;
  };

  const handleChange = onChange => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        onChange({
          lat,
          lng,
          address: place.formatted_address,
          building: place.business_status ? place.name : '',
        });
        setInputValue(`${place.business_status ? place.name : ''} ${place.formatted_address}`);
      }
    }
  };

  if (!isLoaded) {
    return <div>ładowanie...</div>;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <Autocomplete onLoad={handleLoad} onPlaceChanged={() => handleChange(onChange)}>
            <input
              style={{ border: '1px solid #949494', padding: '12px', width: '100%' }}
              type="text"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Wpisz adres"
            />
          </Autocomplete>
          {error && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
        </div>
      )}
    />
  );
};

export default LocationInput;
