import React, { createContext, useEffect, useRef, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { Avatar, LayoutWrapperFooter, Page, SectionExternalProducts } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer.js';

import css from './styles.css';

import { useQuery } from 'react-query';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.js';
import { compose } from 'redux';
import FooterV2 from '../../components/Footer/FooterV2.js';
import AddressFromCoordinates from '../../components/Map/AddressFromCoordinates.js';
import MapWithMarker from '../../components/Map/MapWithMarker.js';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { apiSdk } from '../../queries/api.js';
import { report } from '../../util/api';
import { useIsMobile } from '../../util/hooks/useIsMobile.js';
import BookingPanel from './components/bookingPanel/BookingPanel.js';
import GeneralInfo from './components/generalInfo/GeneralInfo.js';
import ImageGallery from './components/ImageGallery/ImageGallery.js';
import ImportantInfo from './components/importantInfo/ImportantInfo.js';
import MessageForm from './components/MessageForm.js';
import TextDisplay from './components/textDisplay/TextDisplay.js';
import { loadData } from './ListingPage.duck';

const useGetListingId = () => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname === '/l/event-new') {
    history.push('/l/event/00000000-0000-0000-0000-000000000000/new/0');
  }

  const matchWithSlug = matchPath(location.pathname, {
    path: '/l/:slug/:listingId',
    exact: true,
    strict: false,
  });

  const matchWithoutSlug = matchPath(location.pathname, {
    path: '/l/:listingId',
    exact: true,
    strict: false,
  });

  if (matchWithSlug) {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const isChatId = regex.test(matchWithSlug.params.slug);
    return {
      listingId: matchWithSlug.params.listingId,
      chatId: isChatId ? matchWithSlug.params.slug : null,
    };
  } else if (matchWithoutSlug) {
    return { listingId: matchWithoutSlug.params.listingId, chatId: null };
  } else {
    return { listingId: null, chatId: null };
  }
};

const ListingPageComponent = props => {
  const { listingId, chatId } = useGetListingId();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [availableSeats, setAvailableSeats] = useState(null);

  const listingData = props.getListing(listingId);
  const messageRef = useRef(null);

  const isAuthenticated = typeof window !== 'undefined' && localStorage.getItem('go-sdk-jwt');
  useEffect(() => {
    if (listingId === '161de48b-ef56-418d-b324-941c3f0e142d') {
      report().then(res => {
        const booked = res.booked ? res.booked.count : 0;
        const free = res.free && res.free.byAvailability ? res.free.byAvailability.available : 0;
        const reservedByToken = res.reservedByToken ? res.reservedByToken.count : 0;

        setAvailableSeats({
          free,
          total: booked + free + reservedByToken,
        });
      });
    }
  }, [listingId]);

  const { data, isSuccess } = useQuery('listing.query', () =>
    apiSdk.listings.show(listingId, { include: ['dates', 'author', 'images', 'termsFile'] })
  );

  const isClosed = isSuccess && data.data.attributes.state === 'closed';

  //is standard event or ticketing one
  const isEvent =
    isSuccess &&
    data.data.attributes.publicData &&
    (data.data.attributes.publicData.isEvent ||
      typeof data.data.attributes.publicData.isEvent === 'undefined');

  const [isMessageFormOpen, setIsMessageFormOpen] = useState(false);
  const forcedAddress = data && data.data.attributes.publicData.forcedAddress;
  const provider = isSuccess && data.included.filter(item => item.type === 'user');

  const handleEditButton = () => {
    history.push(`/l/event/${listingId}/edit/0`);
  };

  const handleOpenMessageForm = () => {
    if (isAuthenticated) {
      setIsMessageFormOpen(true);
    } else {
      localStorage.setItem('redirectUrl', location.pathname);
      history.push('/login');
    }
  };

  const images = isSuccess
    ? data.included
        .filter(item => item.type === 'image')
        .map(image => image.attributes.variants['landscape-crop2x'].url)
    : [];

  const facebookImages =
    listingData &&
    Object.values(listingData.image).map(image => image.attributes.variants['facebook'].url);

  const twitterImages =
    listingData &&
    Object.values(listingData.image).map(image => image.attributes.variants['twitter'].url);

  useEffect(() => {
    if (chatId) {
      messageRef.current && messageRef.current.scrollIntoView({ behavior: 'smooth' });
      setIsMessageFormOpen(true);
    }
  }, [chatId, isSuccess, messageRef]);

  const providerProfile =
    data &&
    data.included.filter(item => item.type === 'user')[0].id.uuid ===
      'da835a57-cbb2-4332-8360-b64e86f8fda3'
      ? { displayName: 'Polska Liga Siatkówki S.A.' }
      : data
      ? data.included.filter(item => item.type === 'user')[0].attributes.profile
      : {};

  return !listingData ? null : (
    <Page
      title={`${
        listingData && listingData.listing[listingId]
          ? listingData.listing[listingId].attributes.title
          : ''
      } | Decathlon Go`}
      scrollingDisabled={false}
      author={
        listingData && listingData.listing[listingId]
          ? Object.values(listingData.user)[0].attributes.profile.displayName
          : ''
      }
      contentType="website"
      description={
        listingData && listingData.listing[listingId]
          ? listingData.listing[listingId].attributes.description
          : ''
      }
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description:
          listingData && listingData.listing[listingId]
            ? listingData.listing[listingId].attributes.description
            : '',
        name: `${
          listingData && listingData.listing[listingId]
            ? listingData.listing[listingId].attributes.title
            : ''
        } | Decathlon Go`,
        image:
          listingData &&
          Object.values(listingData.image).map(
            image => image.attributes.variants['landscape-crop2x'].url
          ),
        location: {
          '@type': 'Place',
          address:
            listingData && listingData.listing[listingId]
              ? listingData.listing[listingId].attributes.geolocation
              : '',
        },
        startDate:
          listingData && listingData['listing-date']
            ? Object.values(listingData['listing-date']).map(date => date.attributes.date)
            : '',
      }}
    >
      <TopbarContainer />
      {isSuccess && (
        <article
          style={{
            maxWidth: '1430px',
            margin: '0 auto',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BookingPanel
            data={data.data}
            dates={data.included.filter(item => item.type === 'listing-date')}
            isEvent={isEvent}
            availableSeats={availableSeats}
          />

          {!!data.data.attributes.isMy && (
            <div className={css.myOfferBanner}>
              <p>To jest Twoja oferta</p>
              <button onClick={handleEditButton}>Edytuj</button>
            </div>
          )}
          {!!isClosed && (
            <div className={css.isClosedBanner}>
              <p>To wydarzenie jest aktualnie niedostępne.</p>
              <Link
                to={
                  '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954'
                }
              >
                Zobacz pozostałe wydarzenia.
              </Link>
            </div>
          )}
          <div className={css.container}>
            <div className={css.header}>
              <h1>{data.data.attributes.title}</h1>
              <Link
                to={`/profil/${data.included.filter(item => item.type === 'user')[0].id.uuid}`}
                className={css.authorName}
              >
                {providerProfile.displayName || providerProfile.firstName}
              </Link>
            </div>

            <ImageGallery images={images} />

            <section className={css.userInfo}>
              <Avatar user={props.user} />
              <p>{providerProfile.displayName || providerProfile.firstName}</p>
            </section>

            <GeneralInfo data={data} isEvent={isEvent} />

            <section className={css.section}>
              <h2>O wydarzeniu</h2>
              <TextDisplay text={data.data.attributes.description} />
            </section>

            <ImportantInfo data={data} />

            <SectionExternalProducts
              breakpoint={1230}
              category="Product Page"
              sectionClassName={css.section}
              slidesToShow={isMobile ? 1 : 3}
              sport={data.data.attributes.publicData.sport}
              titleClassName={css.sectionTitle}
              urlCampaign="event_page"
              listingId={{ uuid: listingId }}
            />
            <section
              className={css.section}
              style={{ borderTop: 'solid 1px #E1E0DF', borderBottom: 'solid 1px #E1E0DF' }}
            >
              <h2>Lokalizacja</h2>
              {forcedAddress ? (
                forcedAddress
              ) : isEvent ? (
                <>
                  <p>
                    Adres wydarzenia:{' '}
                    <AddressFromCoordinates
                      lat={data.data.attributes.geolocation.lat}
                      lng={data.data.attributes.geolocation.lng}
                      location={data.data.attributes.location}
                    />
                  </p>
                  <p>Dodatkowe wskazówki: {data.data.attributes.publicData.directions}</p>
                  <MapWithMarker
                    lat={data.data.attributes.geolocation.lat}
                    lng={data.data.attributes.geolocation.lng}
                  />
                </>
              ) : data.data.attributes.publicData.geolocation ? (
                <>
                  <p>
                    Adres wydarzenia:{' '}
                    <AddressFromCoordinates
                      lat={data.data.attributes.publicData.geolocation.lat}
                      lng={data.data.attributes.publicData.geolocation.lng}
                      location={data.data.attributes.location}
                    />
                  </p>

                  <MapWithMarker
                    lat={data.data.attributes.publicData.geolocation.lat}
                    lng={data.data.attributes.publicData.geolocation.lng}
                  />
                </>
              ) : (
                <p>{data.data.attributes.publicData.stadium.split('_')[1]}</p>
              )}
            </section>

            <section ref={messageRef}>
              <div className={css.userInfo} style={{ paddingTop: 0 }}>
                <Avatar user={props.user} />
                <p>{props.user && props.user.attributes.profile.displayName}</p>
              </div>
              <p className={css.userBio}>{props.user && props.user.attributes.profile.bio}</p>
              <div className={css.buttons}>
                <button onClick={handleOpenMessageForm}>Skontaktuj się</button>
                <Link to={`/profil/${provider[0].id.uuid}`}>
                  <button>Inne wydarzenia organizatora</button>
                </Link>
              </div>
              <MessageForm
                listingId={listingId}
                messageChatId={chatId}
                isMessageFormOpen={isMessageFormOpen}
                setIsMessageFormOpen={setIsMessageFormOpen}
                isMy={!!data.data.attributes.isMy}
                provider={provider}
              />
            </section>
            {/* <Reviews listingId={listingId} /> */}
          </div>
        </article>
      )}
      <LayoutWrapperFooter>
        <FooterV2 />
      </LayoutWrapperFooter>
    </Page>
  );
};

// export default ListingPage;

// const formatDescription = (text) => {
//   return text.split('\n').map((str, index) => (
//     <React.Fragment key={index}>
//       {str}
//       <br />
//     </React.Fragment>
//   ));
// };

//ten context do wywalenie pozniej
export const SelectedEventDateContext = createContext(null);

// /* eslint-disable  */
// import React, { Component, createContext } from 'react';
// import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
// import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
// import { compose } from 'redux';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import { FacebookShareButton, FacebookIcon } from 'react-share';
// import ReactGA from 'react-ga';
// import { IconChevronRight } from '../../components';
// import config from '../../config';
// import routeConfiguration from '../../routeConfiguration';
// import {
//   LISTING_STATE_PENDING_APPROVAL,
//   LISTING_STATE_CLOSED,
//   propTypes,
// } from '../../util/types';
// import { types as sdkTypes } from '../../util/sdkLoader';
// import {
//   LISTING_PAGE_DRAFT_VARIANT,
//   LISTING_PAGE_PENDING_APPROVAL_VARIANT,
//   LISTING_PAGE_PARAM_TYPE_DRAFT,
//   LISTING_PAGE_PARAM_TYPE_EDIT,
//   createSlug,
// } from '../../util/urlHelpers';
// import { withViewport } from '../../util/contextHelpers';
// import { formatMoney } from '../../util/currency';
// import {
//   createResourceLocatorString,
//   findRouteByRouteName,
// } from '../../util/routes';
// import {
//   ensureListing,
//   ensureOwnListing,
//   ensureUser,
//   userDisplayNameAsString,
// } from '../../util/data';
// import { richText } from '../../util/richText';
// import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import {
//   manageDisableScrolling,
//   isScrollingDisabled,
// } from '../../ducks/UI.duck';
// import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
// import {
//   Page,
//   NamedLink,
//   NamedRedirect,
//   LayoutSingleColumn,
//   LayoutWrapperTopbar,
//   LayoutWrapperMain,
//   LayoutWrapperFooter,
//   Footer,
//   BookingPanel,
//   Modal,
//   ExternalLink,
//   SectionExternalProducts,
// } from '../../components';
// import { TopbarContainer, NotFoundPage } from '../../containers';
// import classNames from 'classnames';

// import { sendEnquiry, loadData, setInitialValues } from './ListingPage.duck';
// import {
//   sendEnquiry,
//   loadData,
//   setInitialValues,
//   fetchTransactionLineItems,
// } from './ListingPage.duck';
// import SectionImages from './SectionImages';
// import SectionAvatar from './SectionAvatar';
// import SectionHeading from './SectionHeading';
// import SectionDescriptionMaybe from './SectionDescriptionMaybe';
// import SectionHostMaybe from './SectionHostMaybe';
// import SectionRulesMaybe from './SectionRulesMaybe';
// import SectionMapMaybe from './SectionMapMaybe';
// import SectionLevels from './SectionLevels';
// import SectionMainInfo from './SectionMainInfo';
// import SectionAge from './SectionAge';
// import SectionAdditionalInfo from './SectionAdditionalInfo';
// import YoutubePlayer from './Youtube';
// import FacebookPlayer from './Facebook';
// import css from './ListingPage.css';
// import { openMissingInfoModal } from '../../components/ModalMissingInformation/MissingModalInformation.duck';
// import moment from 'moment';
// import { createFormElement } from '../../../src/components/EditListingAdditionalFormPanel/utils'
// import active from './active.png'
// import { initiateTransactionForSTSEvent, showCurrentUserData } from '../../util/stsIntitateTransaction.js';

// const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

// const { UUID } = sdkTypes;

// const MAX_TABLET_SCREEN_WIDTH = 1024;
// const MAX_MOBILE_SCREEN_WIDTH = 768;

// export const SelectedEventDateContext = createContext(null)

// const priceData = (price, intl) => {
//   if (price && price.currency === config.currency) {
//     const formattedPrice = formatMoney(intl, price);
//     return { formattedPrice, priceTitle: formattedPrice };
//   } else if (price) {
//     return {
//       formattedPrice: `(${price.currency})`,
//       priceTitle: `Unsupported currency (${price.currency})`,
//     };
//   }
//   return {};
// };

// const categoryLabel = (categories, key) => {
//   const cat = categories.find((c) => c.key === key);
//   return cat ? cat.label : key;
// };

// export class ListingPageComponent extends Component {
//   constructor(props) {
//     super(props);
//     const { enquiryModalOpenForListingId, params } = props;
//     this.state = {
//       pageClassNames: [],
//       imageCarouselOpen: false,
//       enquiryModalOpen: enquiryModalOpenForListingId === params.id,
//       promoModalOpen: false,
//       selectedImageIndex: 0,
//       selectedEventDate: null,
//       // bookingDataFromLocalStorage: null,
//     };

//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.onContactUser = this.onContactUser.bind(this);
//     this.onOpenPromoModal = this.onOpenPromoModal.bind(this);
//     this.onClosePromoModal = this.onClosePromoModal.bind(this);
//     this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
//   }

//   componentDidMount() {
//     const replace = this.props.history.location.search.split('=')[this.props.history.location.search.split('=').length - 1]
//     if (replace === 'replace') {
//       const bookingData = JSON.parse(localStorage.getItem('bookingData'));
//       if (bookingData) {
//         this.handleSubmit(bookingData.values, bookingData.paymentType);

//         localStorage.removeItem('bookingData');
//       } else {
//         this.handleSubmit([], null);
//       }
//     }
//   }

//   handleSubmit(values, paymentType = 'stationary_payment') {
//     const {
//       history,
//       getListing,
//       params,
//       callSetInitialValues,
//       onInitializeCardPaymentData,
//     } = this.props;
//     const listingId = new UUID(params.id);
//     const listing = getListing(listingId);

//     if (listing.attributes.metadata.sts_run) {

//       let choosenTimeSlots = []

//       choosenTimeSlots = this.props.timeSlots
//         ? this.props.timeSlots.reduce((acc, item) => {
//           const timeSlotDate = new Date(item.attributes.start)

//           const selectData = listing.attributes.publicData.termins ? listing.attributes.publicData.termins.filter(({ date }) => (new Date(date)).getTime() === timeSlotDate.getTime()) : []

//           const isTerminActive = selectData.length ? true : false
//           if (isTerminActive) {
//             item.attributes.start = selectData[0].date
//             return [...acc, item]
//           }
//           return acc
//         }, []) : []

//       // }
//       const nearestTimeslot = choosenTimeSlots.filter(timeSlot => {
//         return (new Date()).getTime() < (new Date(timeSlot.attributes.start)).getTime()
//       })

//       Promise.all([showCurrentUserData(), initiateTransactionForSTSEvent({
//         listingId,
//         bookingStart: (new Date(nearestTimeslot ? nearestTimeslot[0].attributes.start : null)),
//         bookingEnd: (new Date(nearestTimeslot ? nearestTimeslot[0].attributes.end : null))
//       })
//       ])
//         .then(res => {
//           if (res) {
//             const { email, firstName, lastName } = res[0]
//             window.location.href = `${listing.attributes.metadata.sts_run}?imie=${firstName}&nazwisko=${lastName}&email=${email}&go=1`
//           }
//         })

//     }

//     const { bookingDates, ...bookingData } = values;

//     const now = moment();
//     const startDay = now.startOf('day').add(7, 'days').toDate();
//     const endDay = now.startOf('day').add(1, 'days').toDate();

//     const initialValues = {
//       listing,
//       bookingData,
//       bookingDates: {
//         bookingStart:
//           listing.attributes.publicData.availabilityType === 'permanent'
//             ? startDay
//             : bookingDates.startDate,
//         bookingEnd:
//           listing.attributes.publicData.availabilityType === 'permanent'
//             ? endDay
//             : bookingDates.endDate,
//       },
//       confirmPaymentError: null,
//     };

//     const routes = routeConfiguration();
//     // Customize checkout page state with current listing and selected bookingDates
//     const checkoutPage =
//       paymentType === 'online_payment' ? 'CheckoutPage' : 'FreeCheckoutPage';

//     const { setInitialValues } = findRouteByRouteName(checkoutPage, routes);
//     callSetInitialValues(setInitialValues, initialValues);

//     // Clear previous Stripe errors from store if there is any
//     onInitializeCardPaymentData();

//     //fb event
//     window.fbq('track', '383270155928431', 'InitiateCheckout');
//     window.fbq('trackSingleCustom', '383270155928431', 'Initiate Checkout', {
//       sport: listing.attributes.publicData.sport,
//     });
//     // Redirect to CheckoutPage
//     history.push(
//       createResourceLocatorString(
//         checkoutPage,
//         routes,
//         { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
//         {}
//       )
//     );
//   }

//   onContactUser() {
//     const {
//       currentUser,
//       history,
//       callSetInitialValues,
//       params,
//       location,
//       onOpenMissingNameModal,
//     } = this.props;

//     if (!currentUser) {
//       const state = {
//         from: `${location.pathname}${location.search}${location.hash}`,
//       };

//       // We need to log in before showing the modal, but first we need to ensure
//       // that modal does open when user is redirected back to this listingpage
//       callSetInitialValues(setInitialValues, {
//         enquiryModalOpenForListingId: params.id,
//       });

//       // signup and return back to listingPage.
//       history.push(
//         createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
//         state
//       );
//     } else {
//       if (
//         currentUser.attributes.profile.firstName === 'Nowy' ||
//         currentUser.attributes.profile.lastName === 'Użytkownik'
//       ) {
//         onOpenMissingNameModal();
//       } else {
//         this.setState({ enquiryModalOpen: true });
//       }
//     }
//   }

//   onSubmitEnquiry(values) {
//     const { history, params, onSendEnquiry } = this.props;
//     const routes = routeConfiguration();
//     const listingId = new UUID(params.id);
//     const { message } = values;

//     onSendEnquiry(listingId, message.trim())
//       .then((txId) => {
//         this.setState({ enquiryModalOpen: false });
//         ReactGA.event({
//           category: 'Message',
//           action: 'Send',
//           label: 'via enquiry',
//         });
//         // Redirect to OrderDetailsPage
//         history.push(
//           createResourceLocatorString(
//             'OrderDetailsPage',
//             routes,
//             { id: txId.uuid },
//             {}
//           )
//         );
//       })
//       .catch(() => {
//         // Ignore, error handling in duck file
//       });
//   }

//   onOpenPromoModal() {
//     this.setState({ promoModalOpen: true });
//     ReactGA.event({
//       category: 'App',
//       action: 'Clicked Event Promo Modal',
//       label: this.props.params.slug,
//     });
//   }

//   onClosePromoModal() {
//     this.setState({ promoModalOpen: false });
//   }

//   render() {
//     const {
//       unitType,
//       isAuthenticated,
//       currentUser,
//       getListing,
//       getOwnListing,
//       intl,
//       onManageDisableScrolling,
//       params: rawParams,
//       location,
//       scrollingDisabled,
//       showListingError,
//       sendEnquiryInProgress,
//       sendEnquiryError,
//       timeSlots,
//       fetchTimeSlotsError,
//       categoriesConfig,
//       history,
//       viewport,
//       onFetchTransactionLineItems,
//       lineItems,
//       fetchLineItemsInProgress,
//       fetchLineItemsError,
//     } = this.props;

//     console.log('render listing Page')

//     const setSelectedEventDate = (date) => {
//       const dateObject = new Date(date)
//       this.setState(prev => {
//         return {
//           ...prev,
//           selectedEventDate: dateObject
//         }
//       })
//     }

//     const isTablet = viewport.width < MAX_TABLET_SCREEN_WIDTH;
//     const isMobile = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

//     const listingId = new UUID(rawParams.id);
//     const isPendingApprovalVariant =
//       rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
//     const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
//     const currentListing =
//       isPendingApprovalVariant || isDraftVariant
//         ? ensureOwnListing(getOwnListing(listingId))
//         : ensureListing(getListing(listingId));

//     const listingSlug =
//       rawParams.slug || createSlug(currentListing.attributes.title || '');
//     const params = { slug: listingSlug, ...rawParams };

//     if (currentListing.attributes.publicData.type === 'business_card') {
//       return (
//         <NamedRedirect
//           name="OrganizerPage"
//           params={params}
//           search={location.search}
//         />
//       );
//     }

//     const listingType = isDraftVariant
//       ? LISTING_PAGE_PARAM_TYPE_DRAFT
//       : LISTING_PAGE_PARAM_TYPE_EDIT;
//     const listingTab = isDraftVariant ? 'photos' : 'description';

//     const isApproved =
//       currentListing.id &&
//       currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

//     const pendingIsApproved = isPendingApprovalVariant && isApproved;

//     // If a /pending-approval URL is shared, the UI requires
//     // authentication and attempts to fetch the listing from own
//     // listings. This will fail with 403 Forbidden if the author is
//     // another user. We use this information to try to fetch the
//     // public listing.
//     const pendingOtherUsersListing =
//       (isPendingApprovalVariant || isDraftVariant) &&
//       showListingError &&
//       showListingError.status === 403;
//     const shouldShowPublicListingPage =
//       pendingIsApproved || pendingOtherUsersListing;

//     if (shouldShowPublicListingPage) {
//       return (
//         <NamedRedirect
//           name="ListingPage"
//           params={params}
//           search={location.search}
//         />
//       );
//     }

//     const {
//       description = '',
//       geolocation = null,
//       price = null,
//       title = '',
//       publicData,
//       metadata: {
//         train_at_home,
//         youtube_id: youtubeId,
//         facebook_id: facebookId,
//         ecommerce_products,
//       } = {},
//     } = currentListing.attributes;

//     //to remove after remove old version of events
//     const isOneTimeAvailibilityEvent = publicData.availabilityType && publicData.availabilityType.trim() == 'onetime' && !!publicData.date && !publicData.termins
//     const isRegularAvailibilityEvent = publicData.availabilityType && publicData.availabilityType.trim() == 'regular' && !!publicData.date && !publicData.termins

//     let choosenTimeSlots = [] //isOneTimeAvailibilityEvent ? choosenTimeSlotOneTimeEvent : isRegularAvailibilityEvent ? timeSlots : choosenTimeSlotsNewVersion

//     if (isOneTimeAvailibilityEvent) {
//       choosenTimeSlots = timeSlots && timeSlots.filter((item) => {
//         const timeSlotDate = new Date(item.attributes.start)
//         return timeSlotDate.toLocaleDateString() === new Date(publicData.date).toLocaleDateString()
//       })
//     } else if (isRegularAvailibilityEvent) {
//       choosenTimeSlots = timeSlots
//     }
//     else {
//       choosenTimeSlots = timeSlots
//         ? timeSlots.reduce((acc, item) => {
//           const timeSlotDate = new Date(item.attributes.start)

//           const selectData = publicData.termins ? publicData.termins.filter(({ date }) => (new Date(date)).getTime() === timeSlotDate.getTime()) : []

//           const isTerminActive = selectData.length ? true : false
//           if (isTerminActive) {
//             item.attributes.start = selectData[0].date
//             return [...acc, item]
//           }
//           return acc
//         }, []) : []
//     }
//     const richTitle = richText(title, {
//       longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
//       longWordClass: css.longWord,
//     });

//     const bookingTitle = (
//       <FormattedMessage
//         id="ListingPage.bookingTitle"
//         values={{ title: richTitle }}
//       />
//     );
//     const bookingSubTitle = intl.formatMessage({
//       id: 'ListingPage.bookingSubTitle',
//     });

//     const topbar = <TopbarContainer />;

//     if (showListingError && showListingError.status === 404) {
//       // 404 listing not found

//       return <NotFoundPage />;
//     } else if (showListingError) {
//       // Other error in fetching listing

//       const errorTitle = intl.formatMessage({
//         id: 'ListingPage.errorLoadingListingTitle',
//       });
//       return (
//         <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
//           <LayoutSingleColumn className={css.pageRoot}>
//             <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
//             <LayoutWrapperMain>
//               <p className={css.errorText}>
//                 <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
//               </p>
//             </LayoutWrapperMain>
//             <LayoutWrapperFooter>
//               <Footer />
//             </LayoutWrapperFooter>
//           </LayoutSingleColumn>
//         </Page>
//       );
//     } else if (!currentListing.id) {
//       // Still loading the listing

//       const loadingTitle = intl.formatMessage({
//         id: 'ListingPage.loadingListingTitle',
//       });
//       return (
//         <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
//           <LayoutSingleColumn className={css.pageRoot}>
//             <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
//             <LayoutWrapperMain>
//               <p className={css.loadingText}>
//                 <FormattedMessage id="ListingPage.loadingListingMessage" />
//               </p>
//             </LayoutWrapperMain>
//             <LayoutWrapperFooter>
//               <Footer />
//             </LayoutWrapperFooter>
//           </LayoutSingleColumn>
//         </Page>
//       );
//     }

//     const handleViewPhotosClick = (e, selectedImageIndex = 0) => {
//       // Stop event from bubbling up to prevent image click handler
//       // trying to open the carousel as well.
//       e.stopPropagation();
//       this.setState({
//         selectedImageIndex: selectedImageIndex,
//         imageCarouselOpen: true,
//       });
//     };
//     const authorAvailable = currentListing && currentListing.author;
//     const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
//     const isOwnListing =
//       userAndListingAuthorAvailable &&
//       currentListing.author.id.uuid === currentUser.id.uuid;
//     const showContactUser =
//       authorAvailable && (!currentUser || (currentUser && !isOwnListing));

//     const currentAuthor = authorAvailable ? currentListing.author : null;
//     const ensuredAuthor = ensureUser(currentAuthor);

//     // When user is banned or deleted the listing is also deleted.
//     // Because listing can be never showed with banned or deleted user we don't have to provide
//     // banned or deleted display names for the function
//     const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

//     const { formattedPrice, priceTitle } = priceData(price, intl);

//     const handleBookingSubmit = (values, paymentType) => {
//       const isCurrentlyClosed =
//         currentListing.attributes.state === LISTING_STATE_CLOSED;
//       if (isOwnListing || isCurrentlyClosed) {
//         window.scrollTo(0, 0);
//       } else {
//         this.handleSubmit(values, paymentType);
//       }
//     };

//     const handleBack = () => {
//       if (history.length > 2) {
//         history.goBack();
//       } else {
//         history.push(
//           `/s?address=Polska&bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954&pub_sport=${currentListing.attributes.publicData.sport}`
//         );
//       }
//     };

//     const listingImages = (listing, variantName) =>
//       (listing.images || [])
//         .map((image) => {
//           const variants = image.attributes.variants;
//           const variant = variants ? variants[variantName] : null;

//           // deprecated
//           // for backwards combatility only
//           const sizes = image.attributes.sizes;
//           const size = sizes ? sizes.find((i) => i.name === variantName) : null;

//           return variant || size;
//         })
//         .filter((variant) => variant != null);

//     const facebookImages = listingImages(currentListing, 'facebook');
//     const twitterImages = listingImages(currentListing, 'twitter');
//     const schemaImages = JSON.stringify(facebookImages.map((img) => img.url));
//     const siteTitle = config.siteTitle;
//     const schemaTitle = intl.formatMessage(
//       { id: 'ListingPage.schemaTitle' },
//       { title, siteTitle }
//     );

//     const hostLink = (
//       <NamedLink
//         className={css.authorNameLink}
//         name="ListingPage"
//         params={params}
//         to={{ hash: '#host' }}
//       >
//         {authorDisplayName}
//       </NamedLink>
//     );

//     const category =
//       publicData && publicData.category ? (
//         <span>
//           {categoryLabel(categoriesConfig, publicData.category)}
//           <span className={css.separator}>•</span>
//         </span>
//       ) : null;

//     const startDate =
//       publicData.date && new Date(publicData.date).toISOString();

//     const products =
//       ecommerce_products && !!ecommerce_products.length
//         ? ecommerce_products
//         : config.sportsConfig[publicData.sport].externalProducts;

//     return this.props.history.location.search.split('=')[this.props.history.location.search.split('=').length - 1] !== 'replace' ? <Page
//       title={schemaTitle}
//       scrollingDisabled={scrollingDisabled}
//       author={authorDisplayName}
//       contentType="website"
//       description={description}
//       facebookImages={facebookImages}
//       twitterImages={twitterImages}
//       schema={{
//         '@context': 'http://schema.org',
//         '@type': 'ItemPage',
//         description: description,
//         name: schemaTitle,
//         image: schemaImages,
//         location: {
//           '@type': 'Place',
//           address: publicData.location.address,
//         },
//         startDate: startDate,
//       }}
//     >
//       <LayoutSingleColumn className={css.pageRoot}>
//         <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
//         <LayoutWrapperMain>
//           <div>
//             <div className={css.topButtonsContainer}>
//               <button className={css.backButton} onClick={handleBack}>
//                 <IconChevronRight className={css.chevronLeft} />
//                 Powrót
//               </button>
//               {isOwnListing && (
//                 <NamedLink
//                   className={css.topEditListingLink}
//                   name="EditListingPage"
//                   params={{
//                     id: listingId.uuid,
//                     slug: listingSlug,
//                     type: listingType,
//                     tab: listingTab,
//                   }}
//                 >
//                   Edytuj
//                 </NamedLink>
//               )}
//               <FacebookShareButton
//                 url={`${config.canonicalRootURL}${this.props.match.url}`}
//                 className={css.shareButton}
//                 beforeOnClick={() =>
//                   ReactGA.event({
//                     category: 'Social',
//                     action: 'Clicked Share',
//                     label: schemaTitle,
//                   })
//                 }
//               >
//                 <span>Udostępnij</span>
//                 <FacebookIcon size={32} round={true} />
//               </FacebookShareButton>
//             </div>
//             {isTablet && train_at_home && (youtubeId || facebookId) ? (
//               <div className={css.halfScreenRight}>
//                 <div className={css.videoContainer}>
//                   <div className={css.video}>
//                     {youtubeId && <YoutubePlayer youtubeId={youtubeId} />}
//                     {facebookId && <FacebookPlayer facebookId={facebookId} />}
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <SectionImages
//                 title={title}
//                 listing={currentListing}
//                 isOwnListing={isOwnListing}
//                 editParams={{
//                   id: listingId.uuid,
//                   slug: listingSlug,
//                   type: listingType,
//                   tab: listingTab,
//                 }}
//                 imageCarouselOpen={this.state.imageCarouselOpen}
//                 onImageCarouselClose={() =>
//                   this.setState({ imageCarouselOpen: false })
//                 }
//                 handleViewPhotosClick={handleViewPhotosClick}
//                 onManageDisableScrolling={onManageDisableScrolling}
//                 selectedImageIndex={this.state.selectedImageIndex}
//               />
//             )}
//             <div className={css.contentContainer}>
//               <div
//                 className={classNames(css.mainContent, {
//                   [css.halfScreenLeft]: train_at_home,
//                 })}
//               >
//                 <div style={{ display: 'flex' }}>
//                   <SectionAvatar user={currentAuthor} params={params} />
//                   <SectionHeading
//                     priceTitle={priceTitle}
//                     formattedPrice={formattedPrice}
//                     richTitle={richTitle}
//                     category={category}
//                     hostLink={hostLink}
//                     showContactUser={showContactUser}
//                     onContactUser={this.onContactUser}
//                     title={title}
//                   />
//                 </div>
//                 <SelectedEventDateContext.Provider value={
//                   this.state.selectedEventDate
//                 }>
//                   <SectionMainInfo
//                     intl={intl}
//                     publicData={publicData}
//                     metadata={currentListing.attributes.metadata}
//                     timeSlots={choosenTimeSlots}
//                     hideAdditionalInfo={train_at_home}
//                   />
//                   {!!(publicData.paymentEnabled || publicData.qr_confirmation) && <div className={css.activebanner} >
//                     <div className={css.activeinfo}>
//                       <p>Weź udział w tym wydarzeniu, a <span style={{ fontWeight: 'bold' }}>otrzymasz 20zł na zakupy</span></p>
//                       <p>dzięki Decathlon Active</p>
//                       <p style={{ fontSize: '14px', color: 'white' }}><a href='https://contents.mediadecathlon.com/s1077394/k$7228bf817505c86089887e32f4ce8d72/defaut.pdf' target='_blank' style={{ color: 'white' }}>Szczegóły promocji</a></p>
//                     </div>
//                     <div className={css.activelogo}>
//                       <img src={active} />
//                     </div>
//                   </div>}
//                 </SelectedEventDateContext.Provider>
//                 <SectionDescriptionMaybe description={description} />
//                 {!train_at_home && (
//                   <>
//                     <SectionLevels
//                       publicData={publicData}
//                       options={config.custom.sportLevels}
//                     />
//                     <SectionAge publicData={publicData} />
//                     <SectionRulesMaybe publicData={publicData} />
//                     <SectionAdditionalInfo publicData={publicData} />
//                   </>
//                 )}
//                 <SectionExternalProducts
//                   breakpoint={1230}
//                   category="Product Page"
//                   products={products}
//                   sectionClassName={css.section}
//                   slidesToShow={isMobile ? 2 : 3}
//                   sport={publicData.sport}
//                   titleClassName={css.sectionTitle}
//                   urlCampaign="event_page"
//                   listingId={listingId}
//                 />
//                 {!train_at_home && publicData.event_type !== 'online' && (
//                   <SectionMapMaybe
//                     geolocation={geolocation}
//                     publicData={publicData}
//                     listingId={currentListing.id}
//                   />
//                 )}
//                 <SectionHostMaybe
//                   title={title}
//                   listing={currentListing}
//                   authorDisplayName={authorDisplayName}
//                   onContactUser={this.onContactUser}
//                   isEnquiryModalOpen={
//                     isAuthenticated && this.state.enquiryModalOpen
//                   }
//                   onCloseEnquiryModal={() =>
//                     this.setState({ enquiryModalOpen: false })
//                   }
//                   sendEnquiryError={sendEnquiryError}
//                   sendEnquiryInProgress={sendEnquiryInProgress}
//                   onSubmitEnquiry={this.onSubmitEnquiry}
//                   currentUser={currentUser}
//                   onManageDisableScrolling={onManageDisableScrolling}
//                 />
//               </div>
//               {train_at_home && !isTablet && (
//                 <div className={css.halfScreenRight}>
//                   <div className={css.videoContainer}>
//                     <div className={css.video}>
//                       {youtubeId && <YoutubePlayer youtubeId={youtubeId} />}
//                       {facebookId && (
//                         <FacebookPlayer facebookId={facebookId} />
//                       )}
//                       {!youtubeId && !facebookId && (
//                         <div className={css.placeholder} />
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {!train_at_home && (
//                 <SelectedEventDateContext.Provider value={
//                   setSelectedEventDate
//                 }>
//                   <BookingPanel
//                     availabilityType={publicData.availabilityType}
//                     currentUser={currentUser}
//                     classNameBP={css.bookingPanel}
//                     listing={currentListing}
//                     isOwnListing={isOwnListing}
//                     unitType={unitType}
//                     onSubmit={handleBookingSubmit}
//                     title={bookingTitle}
//                     subTitle={bookingSubTitle}
//                     authorDisplayName={authorDisplayName}
//                     onManageDisableScrolling={onManageDisableScrolling}
//                     timeSlots={choosenTimeSlots}
//                     fetchTimeSlotsError={fetchTimeSlotsError}
//                     bookedDates={this.props.bookedDates}
//                     onFetchTransactionLineItems={onFetchTransactionLineItems}
//                     lineItems={lineItems}
//                     fetchLineItemsInProgress={fetchLineItemsInProgress}
//                     fetchLineItemsError={fetchLineItemsError}
//                   />
//                 </SelectedEventDateContext.Provider>
//               )}
//             </div>
//           </div>
//         </LayoutWrapperMain>
//         <LayoutWrapperFooter>
//           <Footer />
//         </LayoutWrapperFooter>
//       </LayoutSingleColumn>
//       {
//         currentListing.attributes.metadata &&
//         currentListing.attributes.metadata.promo_modal && (
//           <Modal
//             id="Promo.enquiry"
//             isOpen={this.state.promoModalOpen}
//             onClose={this.onClosePromoModal}
//             onManageDisableScrolling={onManageDisableScrolling}
//           >
//             <h2 className={css.contestHeader}>
//               Wygraj {currentListing.attributes.metadata.promo_modal.prize}
//             </h2>

//             <ol className={css.rulesList}>
//               <li>Zapisz się na wydarzenie przez Decathlon GO</li>
//               <li>
//                 Udostępnij wydarzenie klikając przycisk "Udostępnij" obok
//                 nazwy wydarzenia
//               </li>
//               <li>Weź udział w wydarzeniu</li>
//               <li>Napisz recenzję na Decathlon GO</li>
//             </ol>

//             <p>
//               Autor najciekawszej recenzji, który spełni powyższe wymagania
//               otrzyma nagrodę!
//             </p>
//             {currentListing.attributes.metadata.promo_modal.rules_link && (
//               <ExternalLink
//                 href={
//                   currentListing.attributes.metadata.promo_modal.rules_link
//                 }
//               >
//                 Regulamin
//               </ExternalLink>
//             )}
//           </Modal>
//         )
//       }
//     </Page > : null
//   }
// }

// ListingPageComponent.defaultProps = {
//   unitType: config.bookingUnitType,
//   currentUser: null,
//   enquiryModalOpenForListingId: null,
//   showListingError: null,
//   reviews: [],
//   fetchReviewsError: null,
//   timeSlots: null,
//   fetchTimeSlotsError: null,
//   sendEnquiryError: null,
//   bookedDates: [],
//   lineItems: null,
//   fetchLineItemsError: null,
// };

// ListingPageComponent.propTypes = {
//   // from withRouter
//   history: shape({
//     push: func.isRequired,
//   }).isRequired,
//   location: shape({
//     search: string,
//   }).isRequired,

//   unitType: propTypes.bookingUnitType,
//   // from injectIntl
//   intl: intlShape.isRequired,

//   params: shape({
//     id: string.isRequired,
//     slug: string,
//     variant: oneOf([
//       LISTING_PAGE_DRAFT_VARIANT,
//       LISTING_PAGE_PENDING_APPROVAL_VARIANT,
//     ]),
//   }).isRequired,

//   isAuthenticated: bool.isRequired,
//   currentUser: propTypes.currentUser,
//   getListing: func.isRequired,
//   getOwnListing: func.isRequired,
//   onManageDisableScrolling: func.isRequired,
//   scrollingDisabled: bool.isRequired,
//   enquiryModalOpenForListingId: string,
//   showListingError: propTypes.error,
//   callSetInitialValues: func.isRequired,
//   reviews: arrayOf(propTypes.review),
//   fetchReviewsError: propTypes.error,
//   timeSlots: arrayOf(propTypes.timeSlot),
//   fetchTimeSlotsError: propTypes.error,
//   sendEnquiryInProgress: bool.isRequired,
//   sendEnquiryError: propTypes.error,
//   onSendEnquiry: func.isRequired,
//   onInitializeCardPaymentData: func.isRequired,

//   categoriesConfig: array,
//   amenitiesConfig: array,

//   onFetchTransactionLineItems: func.isRequired,
//   lineItems: array,
//   fetchLineItemsInProgress: bool.isRequired,
//   fetchLineItemsError: propTypes.error,
// };

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
    bookedDates,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const { userId } = state.ProfilePage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = state.marketplaceData.entities; //getMarketplaceEntities(state, [ref]);
    return !!Object.keys(listings).length ? listings : null; //listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    user,
    getListing,
    getOwnListing,
    scrollingDisabled: false, //isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    bookedDates,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  };
};

// const mapDispatchToProps = (dispatch) => ({
//   onManageDisableScrolling: (componentId, disableScrolling) =>
//     dispatch(manageDisableScrolling(componentId, disableScrolling)),
//   callSetInitialValues: (setInitialValues, values) =>
//     dispatch(setInitialValues(values)),
//   onSendEnquiry: (listingId, message) =>
//     dispatch(sendEnquiry(listingId, message)),
//   onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
//   onOpenMissingNameModal: () => dispatch(openMissingInfoModal('NAME_MISSING')),
//   onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
//     dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
// });

// // Note: it is important that the withRouter HOC is **outside** the
// // connect HOC, otherwise React Router won't rerender any Route
// // components since connect implements a shouldComponentUpdate
// // lifecycle hook.
// //
// // See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  // withViewport,
  // withRouter,
  connect(mapStateToProps)
  // connect(mapStateToProps, mapDispatchToProps),
  // injectIntl
)(ListingPageComponent);

// ListingPage.setInitialValues = (initialValues) =>
//   setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
