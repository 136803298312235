import React, { useEffect, useRef, useState } from 'react';
import css from './styles.css';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import RequestStatusModal from '../../../../forms/AddEventForm/RequestStatusModal';
import AdditionalForm from '../../../../forms/EventParticipantForm/AdditionalForm';
import ForWhoForm from '../../../../forms/EventParticipantForm/ForWhoForm';
import { apiSdk } from '../../../../queries/api';
import ItemList from './ItemList';
import Payment from './Payment/Payment';
import SelectTicketsAmount from './SelectTicketsAmount/SelectTicketsAmount';
import SidePanelContainer from './SidePanelContainer';
import { addMinutes, formatDate, formatTime } from './utils';
import SelectTicketsAmountFromStadium from './SelectTicketsAmount/SelectTicketsAmountFromStadium';
import Summary from './Summary/Summary';

function duplicateElements(array, times) {
  return array.reduce((res, current) => {
    return res.concat(Array(times).fill(current));
  }, []);
}

const useScroll = () => {
  const initialTopValue = 160;
  const scrollThreshold = 100;
  const [stickyTop, setStickyTop] = useState(initialTopValue);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        const newTop = 60;
        setStickyTop(newTop);
      } else {
        setStickyTop(initialTopValue);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return stickyTop;
};

const BookingPanelTicketing = ({ dates, data, isEvent, availableSeats }) => {
  const history = useHistory();
  const location = useLocation();
  const isClosed = data.attributes.state === 'closed';

  const lowestPrice =
    data.attributes.publicData.sectorsPrice
      .map(
        ({ ticketTypes }) =>
          ticketTypes.filter(({ ticketType }) => ticketType === 'standard')[0].price
      )
      .sort((a, b) => a - b)[0] *
    1.05 +
    2;

  const isWithoutActiveDates =
    !dates.length ||
    dates.every(date => {
      const eventDate = new Date(date.attributes.date);
      eventDate.setMinutes(eventDate.getMinutes() + date.attributes.duration);
      return eventDate < new Date();
    });

  const priceHide = data.attributes.publicData.price_hide;

  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get('mode');

  const isForChildren =
    !!data.attributes.publicData.gender && !!data.attributes.publicData.gender.includes('children');
  const isMyEvent = data.attributes.isMy;

  const [isFormOpen, setIsFormOpen] = useState(false);
  //   const [isAdditionalFormOpen, setIsAdditionalFormOpen] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState(null);
  const [participantsData, setParticipantsData] = useState([]);
  //   const [additionalFormData, setAdditionalFormData] = useState([]);
  //   const [hasAdditionalFormOpen, setHasAdditionalFormOpen] = useState(
  //     !!data.attributes.publicData.additionalForm &&
  //       !!data.attributes.publicData.additionalForm.length
  //   );
  const [participantsLimit, setPaticipantsLimit] = useState();
  const [isAllBooked, setIsAllBooked] = useState(false);
  const [isOpenPaymentForm, setIsOpenPaymentForm] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const [dataToPutIntoTransaction, setDataToPutIntoTransaction] = useState({});
  const [forwardButtonDisabled, setForwardButtonDisabled] = useState(false);
  const [transactionError, setTransactionError] = useState(null);
  const [isSelectTicketsAmountOpen, setIsSelectTicketsAmountOpen] = useState(false);
  const [hasReducedPrice] = useState(
    !!data.attributes.reducedPrice && !!data.attributes.reducedPrice.amount
  );
  const [seats, setSeats] = useState([]);
  //   const [reducedSeats, setReducedSeats] = useState(0);

  const { data: fetchedUSerData, isSuccess } = useQuery('user.current', () =>
    apiSdk.users.currentUser()
  );

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [isNoUser, setIsNoUSer] = useState(true);
  const [isAuthenticated] = useState(!!localStorage.getItem('go-sdk-jwt'));

  const stickyTop = useScroll();

  const activeDates = dates
    .filter(date => {
      const eventDate = new Date(date.attributes.date);
      eventDate.setMinutes(eventDate.getMinutes() + date.attributes.duration);
      return (
        eventDate > new Date() &&
        (date.attributes.participantsLimit - date.attributes.participantsCount > 0 ||
          date.attributes.participantsLimit === null)
      );
    })
    .sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));

  const [, setIsClickedOutsideSIdePanel] = useState(false);

  const dropdownRef = useRef(null);

  const speculateTransaction = useMutation(apiSdk.transactions.create);

  const handleClose = () => {
    setIsFormOpen(false);
    // setAdditionalFormData([]);
    // setIsAdditionalFormOpen(false);
    setIsOpenPaymentForm(false);
    setIsSelectTicketsAmountOpen(false);
    setSelectedDateId(null);
    setParticipantsData([]);
    // setCustomerData({});
    setSeats([]);
    // setReducedSeats(0);
    setForwardButtonDisabled(false);
    history.push(`${location.pathname}`);
  };

  const handleCloseSidePanel = event => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      setIsClickedOutsideSIdePanel(false);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const step = query.get('step');
    const dateId = query.get('dateId');

    if (step === 'form') {
      setIsFormOpen(true);
      setSelectedDateId(dateId);
    } else if (step === 'additionalForm') {
      //   setIsAdditionalFormOpen(true);
    } else if (step === 'selectTickets') {
      setIsSelectTicketsAmountOpen(true);
    } else if (step === 'payment') {
      setIsOpenPaymentForm(true);
    }
  }, [location.search]);

  useEffect(() => {
    setUserData({
      firstName: isSuccess ? fetchedUSerData.data.attributes.profile.firstName : '',
      lastName: isSuccess ? fetchedUSerData.data.attributes.profile.lastName : '',
      email: isSuccess ? fetchedUSerData.data.attributes.email : '',
    });
  }, [isSuccess]);

  useEffect(() => {
    setIsNoUSer(!userData.firstName || !userData.lastName);
  }, [userData]);

  useEffect(() => {
    if (participantsData.filter(participant => participant.isChecked).length > participantsLimit) {
      setIsAllBooked(true);
    } else {
      setIsAllBooked(false);
    }
  }, [participantsData, participantsLimit]);

  const listingMutation = useMutation(apiSdk.listings.update);

  const mutation = useMutation(apiSdk.transactions.create, {
    onSuccess: data => {
      setTransactionData(data);

      if (
        data.data.attributes.processState === 'pending-payment' &&
        !!data.data.attributes.stripePaymentIntent
      ) {
        setIsFormOpen(false);
        setIsSelectTicketsAmountOpen(false);
        setIsOpenPaymentForm(true);
        const query = new URLSearchParams(location.search);
        const dateId = query.get('dateId');
        history.push(`${location.pathname}?step=payment&dateId=${dateId}`);
      } else {
        history.push(`/order/${data.data.id.uuid}/details-success`);
      }
    },
    onError: error => {
      setTransactionError('Jesteś już zapisany na to wydarzenie w wybranym terminie');
      console.error('error', error);
    },
  });

  const handleSelectDate = date => {
    if (isAuthenticated) {
      setIsFormOpen(true);
      setSelectedDateId(date.id.uuid);
      setPaticipantsLimit(
        date.attributes.participantsLimit === null
          ? 1000000
          : date.attributes.participantsLimit - date.attributes.participantsCount
      );
      history.push(`${location.pathname}?step=form&dateId=${date.id.uuid}`);
    } else {
      const location = history.location.pathname;
      localStorage.setItem('redirectUrl', `${location}?step=form&dateId=${date.id.uuid}`);
      history.push('/login');
    }
  };

  const handleSignUp = async (e, isAdditionalFormComplete) => {

    if (isAuthenticated) {
      if (!seats.length) {
        setIsFormOpen(false);
        setIsSelectTicketsAmountOpen(true);
        history.push(`${location.pathname}?step=selectTickets&dateId=${selectedDateId}`);
      } else {
        // if (data.attributes.price.amount !== 0) {
        const transactionData = {
          listingDateId: selectedDateId,
          participants: duplicateElements(participantsData.map(participant => {
            return {
              name: `${participant.firstName} ${participant.lastName}`,
              age: participant.age || null,
            };
          }), seats.length),
          seats: !!seats.filter(s => s.type === 'standard').length ? seats.filter(s => s.type === 'standard').length : 0,
          reducedSeats: !!seats.filter(s => s.type === 'reduced').length ? seats.filter(s => s.type === 'reduced').length : 0,
          metadata: {
            seats: seats,
          },
        };

        setDataToPutIntoTransaction(transactionData);
        if (!isAllBooked) {
          //   mutation.mutate(transactionData);
        }
      }
    } else {
      const location = history.location.pathname;
      localStorage.setItem('redirectUrl', `${location}?step=form&dateId=${selectedDateId}`);
      history.push('/login');
    }
  };

  useEffect(() => {
    if (seats.length && Object.keys(dataToPutIntoTransaction).length) {
      listingMutation.mutateAsync({
        id: data.id.uuid,
        price: seats.find(s => s.type === 'standard') ? seats.find(s => s.type === 'standard').price * 100 : 0,
        reducedPrice: seats.find(s => s.type === 'reduced') ? seats.find(s => s.type === 'reduced').price * 100 : 0,
        paymentEnabled: true,
      }).then(() => history.push(`${location.pathname}?step=summary&dateId=${selectedDateId}`));
    }
  }, [dataToPutIntoTransaction]);

  const query = new URLSearchParams(location.search);
  const step = query.get('step');
  const dateId = query.get('dateId');

  if (isClosed) {
    return (
      <div className={css.container} style={{ top: stickyTop }}>
        <h2>Rezerwuj</h2>
        <p className={css.priceInfo}>Brak dostępnych terminów</p>
      </div>
    );
  }

  return (
    <div className={css.container} style={{ top: stickyTop }}>
      {!!transactionError && (
        <RequestStatusModal
          status={!transactionError ? 'success' : 'error'}
          infoError={transactionError}
          resetStatus={() => setTransactionError(null)}
        />
      )}
      <h2>Rezerwacja</h2>
      {!priceHide && (
        <div className={css.price}>
          <p className={css.priceAmount}>{`Od ${lowestPrice} zł za osobę`}</p>
        </div>
      )}
      {isWithoutActiveDates && <p style={{ color: 'red' }}>Brak dostępnych terminów</p>}
     { console.log(activeDates)}
      <ItemList
        items={
          activeDates.length
            ? activeDates.map((date, index) => {
              const startTime = new Date(date.id.uuid === 'b4250006-3349-4356-a815-169ae3497252' ? '2025-03-14T13:00:00.000+00:00' : date.attributes.date);
              const endTime = addMinutes(startTime, date.attributes.duration);
              return (
                <div key={index} className={css.date}>
                  <div>
                    <h3>{formatDate(startTime)}</h3>
                    <p>{`${formatTime(startTime)}–${formatTime(endTime)}`}</p>
                    {date.attributes.participantsLimit === 50000 ||
                      date.attributes.participantsLimit === null ? (
                      <p className={css.limit}>Bez limitu miejsc</p>
                    ) : (
                      <p className={css.limit}>
                        {
                            (availableSeats && availableSeats.total > 0) ? <>
                          Pozostalo{' '}
                          {availableSeats.free} z{' '}
                          {availableSeats.total} miejsc
                        </> :
                        <>
                          Pozostalo{' '}
                          {date.attributes.participantsLimit - date.attributes.participantsCount} z{' '}
                          {date.attributes.participantsLimit} miejsc
                        </>

                          }
                      </p>
                    )}
                  </div>
                  <button disabled={isMyEvent} onClick={() => handleSelectDate(date)}>
                    Wybierz
                  </button>
                </div>
              );
            })
            : [<p>Brak dostępnych miejsc</p>]
        }
      />

      <div onClick={handleCloseSidePanel} className={css.date} style={{ border: 'none' }}>
        {(isFormOpen || isSelectTicketsAmountOpen || isOpenPaymentForm) && (
          <SidePanelContainer ref={dropdownRef}>
            {isFormOpen && step === 'form' && (
              <ForWhoForm
                participantsData={participantsData}
                setParticipantsData={setParticipantsData}
                isForChildren={isForChildren}
                userData={userData}
                setUserData={setUserData}
                isAllBooked={isAllBooked}
                setForwardButtonDisabled={setForwardButtonDisabled}
                handleClose={handleClose}
              />
            )}

            {/* {isAdditionalFormOpen && (
              <AdditionalForm
                data={data.attributes.publicData.additionalForm}
                participantsData={participantsData}
                additionalFormData={additionalFormData}
                setAdditionalFormData={setAdditionalFormData}
                handleClose={handleClose}
                handleBack={() => {
                  setIsFormOpen(true);
                  setIsAdditionalFormOpen(false);
                  setIsAllBooked(false);
                  setAdditionalFormData([]);
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set('step', 'form');
                  searchParams.set('mode', '');
                  history.push({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                  });
                }}
                setHasAdditionalFormOpen={setHasAdditionalFormOpen}
                hasAdditionalFormOpen={hasAdditionalFormOpen}
              />
            )} */}

            {step === 'selectTickets' && (
              <SelectTicketsAmountFromStadium
                participantsData={participantsData}
                // reducedSeats={reducedSeats}
                // setReducedSeats={setReducedSeats}
                seats={seats}
                setSeats={setSeats}
                priceData={data.attributes.publicData.sectorsPrice}
                //     {
                //   unitPrice: data.attributes.price ? data.attributes.price.amount : 0,
                //   reducedUnitPrice: data.attributes.reducedPrice
                //     ? data.attributes.reducedPrice.amount
                //     : 0,
                // }}
                setForwardButtonDisabled={setForwardButtonDisabled}
                handleClose={handleClose}
                handleBack={() => {
                  setIsSelectTicketsAmountOpen(false);
                  //   setHasAdditionalFormOpen(!!data.attributes.publicData.additionalForm.length);
                  //   if (!!data.attributes.publicData.additionalForm.length) {
                  //     setIsFormOpen(false);
                  //     setIsAdditionalFormOpen(true);
                  //     setSeats(0);
                  //     setReducedSeats(0);
                  //     history.push(
                  //       `${location.pathname}?step=additionalForm&dateId=${selectedDateId}&mode=edit`
                  //     );
                  //   } else {
                  setIsFormOpen(true);
                  //   setIsAdditionalFormOpen(false);
                  setSeats([]);
                  //   setReducedSeats(0);
                  history.push(`${location.pathname}?step=form&dateId=${selectedDateId}`);
                  //   }
                }}
              />
            )}

            {
              step === 'summary' && (
                <Summary
                  speculateFn={() => {
                    const transactionData = {
                      speculative: true,
                      listingDateId: selectedDateId,
                      participants: duplicateElements(participantsData.map(participant => {
                        return {
                          name: `${participant.firstName} ${participant.lastName}`,
                          age: participant.age || null,
                        };
                      }), seats.length),
                      seats: !!seats.filter(s => s.type === 'standard').length ? seats.filter(s => s.type === 'standard').length : 0,
                      reducedSeats: !!seats.filter(s => s.type === 'reduced').length ? seats.filter(s => s.type === 'reduced').length : 0,
                      metadata: {
                        seats: seats,
                      },
                    };
                    ;
                    return () => speculateTransaction.mutateAsync(transactionData)
                  }}
                  handleBackClick={() => {
                    setIsFormOpen(true);
                    setSummaryOpen(false);
                    const query = new URLSearchParams(location.search);
                    const dateId = query.get('dateId');
                    history.goBack()
                    // history.push(`${location.pathname}?step=form&dateId=${dateId}`)
                  }}
                  handleSubmitClick={() => {
                    const transactionData = {
                      listingDateId: selectedDateId,
                      participants: duplicateElements(participantsData.map(participant => {
                        return {
                          name: `${participant.firstName} ${participant.lastName}`,
                          age: participant.age || null,
                        };
                      }), seats.length),
                      seats: !!seats.filter(s => s.type === 'standard').length ? seats.filter(s => s.type === 'standard').length : 0,
                      reducedSeats: !!seats.filter(s => s.type === 'reduced').length ? seats.filter(s => s.type === 'reduced').length : 0,
                      metadata: {
                        seats: seats,
                      },
                    };
                    if (!isAllBooked) {
                      mutation.mutate(transactionData);
                    }
                  }}
                  listingTitle={data.attributes.title}
                />
              )
            }

            {step === 'payment' && (
              <Payment
                data={transactionData}
                handleClose={handleClose}
                handleBack={() => {
                  setIsOpenPaymentForm(false);
                }}
              />
            )}
            {!isEditMode && step !== 'payment' && step !== 'summary' && (
              <button
                disabled={isNoUser || isAllBooked || forwardButtonDisabled}
                style={{ width: '100%', marginTop: '20px' }}
                onClick={handleSignUp}
              >
                Zapisz się
              </button>
            )}
          </SidePanelContainer>
        )}
      </div>
    </div>
  );
};

export default BookingPanelTicketing;
