import React, { useState } from 'react';
import Buttons from '../Buttons/Buttons';
// import css from './SelectField.css';
import { createFormElement } from '../utils';
import css from './styles.css';

const SelectField = ({
  setSelectedTypeOfQuestion,
  setIsOpenAddQuestion,
  setStep,
  setFormElements,
  elementData,
}) => {
  const [title, setTitle] = useState((elementData && elementData.title) || '');
  const [description, setDescription] = useState((elementData && elementData.description) || '');
  const [option, setoption] = useState('');

  const [invalidValueTitle, setInvalidValueTitle] = useState(null);
  const [invalidValueOption, setInvalidValueOption] = useState(null);
  const [invalidValueTitleDescription, setInvalidValueDescription] = useState(null);
  const [options, setoptions] = useState((elementData && elementData.options) || []);
  const [isValidateError, setIsValidateError] = useState(false);

  const handleCancel = () => {
    setIsOpenAddQuestion(false);
    setSelectedTypeOfQuestion(null);
    setStep(null);
  };

  const handleTitleonChange = e => {
    if (e.target.value.trim().length === 0) {
      setTitle(e.target.value);
      setInvalidValueTitle('pole wymagane');
    } else if (e.target.value.length > 50) {
      setInvalidValueTitle('maksymalna długość to 50 znaków');
    } else {
      setTitle(e.target.value);
    }
  };

  const handleDescriptionChange = e => {
    if (e.target.value.trim().length === 0) {
      setDescription(e.target.value);
      setInvalidValueDescription('pole wymagane');
    } else if (e.target.value.length > 50) {
      setInvalidValueDescription('maksymalna długość to 50 znaków');
    } else {
      setDescription(e.target.value);
    }
  };

  const handleOptionChange = e => {
    if (e.target.value.length > 50) {
      setInvalidValueOption('maksymalna długość to 50 znaków');
    } else {
      setoption(e.target.value);
    }
  };

  const handleAddOption = option => {
    if (option.trim().length === 0) {
      setInvalidValueOption('pole wymagane');
    } else if (options.length > 49) {
      setInvalidValueOption('maksymalnie 50 opcji');
    } else {
      setoptions(options => [...options, option]);
      setoption('');
      setInvalidValueOption(null);
    }
  };

  const handleDeleteOption = option => {
    setoptions(options => options.filter(o => o !== option));
  };

  const hadnleCreateSelectElement = () => {
    const newelementData = {
      title,
      description,
      options,
      selectedTypeOfQuestion: 'selectField',
    };
    const element = createFormElement(newelementData);

    const elementToSave = {
      id: title,
      elementData: newelementData,
      element,
    };

    if (title && description && options.length) {
      setIsValidateError(false);
      setFormElements(prev => {
        if (elementData && elementData.title) {
          let elementToUpdate = prev.findIndex(el => el.id === elementData.title);
          const copy = prev;
          copy[elementToUpdate] = elementToSave;
          return copy;
        } else {
          return [...prev, elementToSave];
        }
      });
      setIsOpenAddQuestion(false);
      setSelectedTypeOfQuestion(null);
      setStep(null);
    } else {
      setIsValidateError(true);
    }
  };

  return (
    <>
      <div className={css.curtain} />
      <div className={css.container}>
        <div style={{ overflowY: 'auto', maxHeight: '80vh' }}>
          <h2 className={css.header}>
            Dostosuj pole jednokrotnego wyboru
            <span style={{ fontWeight: '400' }} onClick={handleCancel}>
              x
            </span>
          </h2>
          <label className={css.label}>
            <p>
              Nadaj tytuł ( przykład: Rozmiar koszulki )
              <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
            </p>
            <input
              className={css.input}
              type="text"
              value={title}
              onChange={handleTitleonChange}
            ></input>
            {invalidValueTitle && <span className={'css.invalidmessage'}>{invalidValueTitle}</span>}
          </label>
          <label className={css.label}>
            <p>
              Podaj opis (przykład: Wybierz rozmiar koszulki )
              <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
            </p>
            <input
              className={css.input}
              type="text"
              value={description}
              onChange={handleDescriptionChange}
            ></input>
            {invalidValueTitleDescription && (
              <span className={'css.invalidmessage'}>{invalidValueTitleDescription}</span>
            )}
          </label>
          <ul>
            {!!options.length && 'Dodane opcje:'}
            {options.map(option => {
              return (
                <li className={css.listItem} key={option}>
                  {option}
                  <button
                    className={css.removeButton}
                    onClick={() => handleDeleteOption(option)}
                    type="button"
                  >
                    usuń
                  </button>
                </li>
              );
            })}
          </ul>
          <label className={css.label}>
            Dodaj odpowiedzi:
            <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
            <div>
              <input
                className={css.input}
                type="text"
                value={option}
                onChange={handleOptionChange}
              ></input>{' '}
              <button
                className={css.addButton}
                onClick={() => handleAddOption(option)}
                type="button"
              >
                dodaj
              </button>
            </div>
            {invalidValueOption && <span>{invalidValueOption}</span>}
          </label>
          {isValidateError && <p style={{ color: 'red' }}>Uzupełnij wymagane pola</p>}
        </div>
        {/* </div>
      <div> */}
        <Buttons
          // setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          // setIsOpenAddQuestion={setIsOpenAddQuestion}
          // setStep={setStep}
          hadnleCreateSelectElement={hadnleCreateSelectElement}
          isEdited={!!elementData}
        />
      </div>
    </>
  );
};

export default SelectField;
