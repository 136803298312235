import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import LocationInput from './LocationInput';

import css from './styles.css';
import FormItem from '../../components/FormItem';
import { updateEvent } from '../api';
import RequestStatusModal from '../RequestStatusModal';

const Location = () => {
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const [updateSucces, setUpdateSucces] = React.useState(null);

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  // const {lat, lng} = formData.location.location;
  // console.log('formData.location', formData.location);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    values: formData.location,
  });

  const listingTitle = formData.generalInfo.eventTitle;

  const onSubmit = async data => {
    if (!errors.forWho && !errors.level) {
      updateFormData(data, 'location');
    }
    const updateStatus = await updateEvent({
      id: listingId,
      geolocation: { lat: data.location.lat, lng: data.location.lng },
      location: {
        address: data.location.address,
        building: data.location.building,
        forcedAddress: data.location.address === 'Polska' ? 'cała Polsa' : null,
      },
      publicData: {
        directions: data.directions,
      },
    });
    updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');
  };

  const submitButtonLabel = useMemo(
    () => (isLastStep ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.container}>
      <h1 className={css.header}>
        <LeftArrowIcon />
        Lokalizacja
      </h1>

      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}

      <FormItem label={'Lokalizacja'} classes="displayBlock">
        <LocationInput
          control={control}
          name="location"
          apiKey={apiKey}
          initialAddress={formData.location ? formData.location.location : ''}
        />
      </FormItem>

      <FormItem label={'Dodatkowe wskazówki'}>
        <textarea
          className={css.textarea}
          {...register('directions', { required: false })}
          lines={5}
          placeholder={'Podaj dodatkowe wskazówki jak trafić na miejsce'}
        />
      </FormItem>

      <div className={css.buttonContainer}>
        <button type="submit" className={css.submitClasses}>
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default Location;
