import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/addEventForm/leftArrowIcon.svg';
import Switcher from '../../../../../forms/components/Switcher/Switcher';
import { useSwitcher } from '../../../../../forms/components/Switcher/useSwitcher';
import css from './styles.css';

const SelectTicketsAmountFromStadium = ({
  participantsData,
  handleBack,
  handleClose,
  setForwardButtonDisabled,
  priceData,
  seats,
  //   reducedSeats,
  setSeats,
  //   setReducedSeats,
}) => {
  //   const maxTicketsCount = participantsData.reduce((acc, curr) => acc + curr.isChecked, 0);
  const workspaceKey = process.env.REACT_APP_WORKSPACE_KEY;

  const handleOnObjectSelected = (object, selectedTicketType) => {
    console.log(object)
    setSeats(prev =>
      !!prev.filter(({ id }) => id === object.id).length
        ? prev.filter(({ id }) => id === object.id)
        : [...prev, { id: object.id, label: object.label, price: selectedTicketType.price, type: object.selectedTicketType }]
    );
  };

  const handleOnObjectDeselected = (object, selectedTicketType) => {
    console.log(object)
    setSeats(prev => prev.filter(({ id }) => id !== object.id));
  };
  //   const ticketTypeSwitcher = useSwitcher(['NORMALNY', 'ULGOWY']);

  //   const [error, setError] = useState(null);

  //   useEffect(() => {
  //     if (seats + reducedSeats !== maxTicketsCount) {
  //       setForwardButtonDisabled(true);
  //       if (!!seats || !!reducedSeats) {
  //         setError('Wybierz łączną liczbę biletów równą liczbie uczestników');
  //       }
  //     } else {
  //       setError(null);
  //       setForwardButtonDisabled(false);
  //     }
  //   }, [seats, reducedSeats]);

  //   useEffect(() => {
  //     if (maxTicketsCount === 1) {
  //       if (ticketTypeSwitcher.selected === 'NORMALNY') {
  //         setSeats(1);
  //         setReducedSeats(0);
  //       } else {
  //         setReducedSeats(1);
  //         setSeats(0);
  //       }
  //     }
  //   }, [ticketTypeSwitcher]);

  useEffect(() => {
    async function loadScript() {
      try {
        // const script = document.createElement('script');
        // script.src = "https://cdn-eu.seatsio.net/chart.js"
        // document.head.appendChild(script);
        // console.log('ssda')
        // console.log(window.seatsio)
        const x = new window.seatsio.SeatingChart({
          divId: "mapContainerVipId",
          pricing: priceData,
          onObjectSelected: handleOnObjectSelected,
          onObjectDeselected: handleOnObjectDeselected,
          session: 'continue',
          mode: null,
          workspaceKey: '31b95431-d78a-4b5c-a61f-2c993d69bd9d',
          event: '274b8ab1-6e10-431a-a4a2-57b13b8de921',
          priceFormatter: function (price) {
            return price + ' zł';
          }
        }).render();

        // document.head.appendChild(script);
        // const response = await fetch('/stadium/map/asset-manifest.json');
        // const manifest = await response.json();
        // const mainScript = manifest['files']['main.js'];
        // const mainCss = manifest.files['main.css'];

        // if (mainCss) {
        //   const link = document.createElement('link');
        //   link.rel = 'stylesheet';
        //   link.href = `/stadium/map${mainCss}`;
        //   document.head.appendChild(link);
        // }

        // if (mainScript) {
        //   const script = document.createElement('script');
        //   script.src = `/stadium/map${mainScript}`;
        //   script.onload = () => {
        //     window.renderMap('mapContainerVipId', {
        //       pricing: priceData,
        //       handleOnObjectSelected: handleOnObjectSelected,
        //       handleOnObjectDeselected: handleOnObjectDeselected,
        //       session: 'continue',
        //       mode: null,
        //       workspaceKey,
        //       event: '274b8ab1-6e10-431a-a4a2-57b13b8de921',
        //     });
        //   };
        //   document.body.appendChild(script);
        // }
      } catch (error) {
        console.error('Błąd podczas ładowania skryptu:', error);
      }
    }

    loadScript();
  }, []);

  return (
    <div className={css.formContainer}>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            onClick={handleBack}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              background: 'none',
              fontSize: '20px',
              color: '#000',
              margin: '5px 0 auto',
              padding: '0 5px 0 0',
            }}
          >
            <LeftArrowIcon />
          </button>
          Wybór rodzaju biletu
        </div>
        <span onClick={handleClose}>x</span>
      </h2>
      <div id="mapContainerVipId"></div>

      {/* {maxTicketsCount === 1 ? (
        <div style={{ margin: '20px auto' }}>
          <p className={css.amountParagraph}>Wybierz rodzaj biletu</p>
          <div className={css.priceContainer}>
            <p className={css.price}>
              Bilet normalny<span>{priceData.unitPrice / 100} zł</span>
            </p>
            <p className={css.price}>
              Bilet ulgowy<span>{priceData.reducedUnitPrice / 100} zł</span>
            </p>
          </div>
          <Switcher {...ticketTypeSwitcher} />
        </div>
      ) : (
        <div style={{ margin: '20px auto' }}>
          <p className={css.amountParagraph}>Ilość biletów normalnych</p>
          <select
            style={{
              border: 'solid 1px #949494',
              padding: '5px 10px',
              margin: '10px auto 20px auto',
            }}
            value={seats}
            onChange={e => setSeats(Number(e.target.value))}
          >
            {[...Array(maxTicketsCount + 1 - reducedSeats).keys()].map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </select>
          <p className={css.amountParagraph}>Ilość biletów ulgowych</p>
          <select
            style={{
              border: 'solid 1px #949494',
              padding: '5px 10px',
              margin: '10px auto 20px auto',
            }}
            value={reducedSeats}
            onChange={e => setReducedSeats(Number(e.target.value))}
          >
            {[...Array(maxTicketsCount + 1 - seats).keys()].map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </select>
          {!!error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      )} */}
    </div>
  );
};

export default SelectTicketsAmountFromStadium;
