import React from 'react';
import FormItem from '../../../components/FormItem';
import css from './styles.css';

const options = [
  { label: 'Zajęcia', value: 'activity' },
  { label: 'Treningi', value: 'training' },
  { label: 'Zawody i turnieje', value: 'competition' },
  { label: 'Konkursy', value: 'contest' },
  { label: 'Mecze', value: 'match' },
  { label: 'Inne', value: 'other' },
];

const EventType = ({ register, errors }) => (
  <FormItem label={'Rodzaj wydarzenia'}>
    <select
      defaultValue={''}
      className={`${css.eventTypeContainer} ${css.select}`}
      {...register('eventType', { required: true })}
    >
      <option value={''} disabled>
        wybierz rodzaj wydarzenia
      </option>
      {// ['Zajęcia', 'Treningi', 'Zawody i turnieje', 'Konkursy', 'Mecze', 'Inne']
      options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </select>
    {errors.eventType && errors.eventType.type === 'required' && (
      <p style={{ color: '#f00' }} role="alert">
        Rodzaj wydarzenia jest wymagany
      </p>
    )}
  </FormItem>
);

export default EventType;
