// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import { postCSV } from './getEventDetailsData';

import { createGoSDK } from '@dktunited/sporteo-sdk';
import axios from 'axios';

export const sdkGo = createGoSDK(`${process.env.REACT_APP_API_URL}/api`, {
  axios,
  noAuth: typeof window === 'undefined',
  useUnsafeRefreshToken: false, // you can set it when using localhost to test front app. It forces refreshToken to be stored in localStorage, instead of secure http-only cookies, to avoid blocking by browser because of interdomain policies
});

export const loginWithGoBackend = async () => {
  const res = await sdkGo.authManager.loginWithDecathlon();
};

export const apiBaseUrl = () => {
  const port = parseInt(process.env.PORT, 10);

  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;
  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }
  // Otherwise, use the same domain and port as the frontend
  if (typeof window !== 'undefined') {
    return `${window.location.origin}`;
  }
};

export const getAuth = async submitSignupSuccess => {
  const baseUrl = apiBaseUrl();
  if (typeof window !== 'undefined') {
    const location = window.location.pathname;
    const locationFromLocalStorage = localStorage.getItem('redirectUrl') || '/';
    const from = location !== locationFromLocalStorage ? locationFromLocalStorage : location;
    await loginWithGoBackend();
    // window.location.href = `${baseUrl}/api/auth?from=${from}&defaultReturn=${location}`;
    submitSignupSuccess();
    // localStorage.removeItem('redirectUrl');
  }
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, {
    typeHandlers,
    verbose: config.sdk.transitVerbose,
  });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const newPost = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: JSON.stringify(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  const { isSpeculative, bookingData, bodyParams, queryParams } = body;

  return fetch('/api/initiate-privileged', {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  });

  // return post('/api/initiate-privileged', { isSpeculative, bookingData, bodyParams, queryParams });
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const getCustomersData = (listingId = '', choosenEventDate = '') => {
  const body = { listingId: listingId, choosenEventDate: choosenEventDate };

  const url = `${apiBaseUrl()}/api/event-customers`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options);
  // .then((res) => { })
  // .catch((e) => console.error(e));
};

export const getEventDetailsData = (listingId = '', choosenEventDate = '') => {
  const body = { listingId: listingId, choosenEventDate: choosenEventDate };
  return postCSV('/api/event-details', body, 'csv');
};

export const getEventDetailsDataPdf = (listingId = '', choosenEventDate = '') => {
  const body = { listingId: listingId, choosenEventDate: choosenEventDate };
  return postCSV('/api/event-details-pdf', body, 'pdf');
};

// export const setMarketingConsents = (accesToken) => {

//   const body = { accesToken };

//   const url = `${apiBaseUrl()}/api/marketing`;
//   const options = {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(body),
//   };
//   return fetch(url, options)
//     .then((res) => { })
//     .catch((e) => console.error(e));
//   // return post('/api/marketing');
// };

// export const setLoyalityConsents = (accesToken) => {

//   const body = { accesToken };

//   const url = `${apiBaseUrl()}/api/loyality`;
//   const options = {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(body),
//   };
//   return fetch(url, options)
//     .then((res) => { })
//     .catch((e) => console.error(e));
// };

// export const getLoyalityConsent = (accesToken) => {
//   const url = `${apiBaseUrl()}/api/loyality?accesToken=${accesToken}`;
//   const options = {
//     method: 'GET',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//   };
//   return fetch(url, options);
// };

export const duplicateEvent = (listingId = '') => {
  const body = { listingId: listingId };
  return newPost('/api/duplicate', body);
};

export const getCategories = async chartKey => {
  const url = `${apiBaseUrl()}/api/categories?chartKey=${chartKey}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const data = await fetch(url, options);
  return data.json();
};

export const getCharts = async () => {
  const url = `${apiBaseUrl()}/api/charts`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const data = await fetch(url, options);
  return data.json();
};


export const report = async () => {
  const url = `${apiBaseUrl()}/api/report`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const data = await fetch(url, options);
  return data.json();
};

export const bookSeats = async body => {
  const url = `${apiBaseUrl()}/api/book`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const data = await fetch(url, options);
  console.log('data', data);
  return data.json();
};

export const getOwnListings = async params => {
  const body = params;

  const url = `${apiBaseUrl()}/api/own-listings`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const data = await fetch(url, options);
  return data.json();
};

export const setTransationMetaData = async (transactionId, data) => {
  const body = { transactionId, data };

  const url = `${apiBaseUrl()}/api/save-metadata`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options)
    .then(res => {})
    .catch(e => console.error(e));
};

export const generateQrCode = async (data, filename, ticketsNumbers) => {
  const body = { data, filename, ticketsNumbers };

  const url = `${apiBaseUrl()}/api/generate-qr-code`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options)
    .then(res => {
      return res.json();
    })
    .catch(e => console.error(e));
};

export const uploadFileToAWS = (bucketName, fileName) => {
  const body = { bucketName, fileName };

  const url = `${apiBaseUrl()}/api/aws-upload`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options)
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    })
    .catch(e => console.error(e));
};
