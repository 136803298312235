import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { compose } from 'redux';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import classNames from 'classnames';
// import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
// import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
// import routeConfiguration from '../../routeConfiguration';
// import { propTypes } from '../../util/types';
// import { ensureListing, ensureTransaction } from '../../util/data';
// import { dateFromAPIToLocalNoon } from '../../util/dates';
// import { createSlug } from '../../util/urlHelpers';
// import { txIsPaymentPending } from '../../util/transaction';
// import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
// import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import Slider from 'react-slick';
// import Cookies from 'js-cookie';
// import classNames from 'classnames';
// import { IconArrowRight, IconChevronBigLeft, IconChevronBigRight } from '../../components';
// import { recommendationApi } from '../../components/SectionExternalProducts/api';

import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
// import {
//   acceptSale,
//   declineSale,
//   loadData,
//   setInitialValues,
//   sendMessage,
//   sendReview,
//   fetchMoreMessages,
//   fetchTransactionLineItems,
//   cancelSale
// } from './TransactionPage.duck';
import css from './TransactionPage.css';

// const PROVIDER = 'provider';
// const CUSTOMER = 'customer';

// // TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
// export const TransactionPageComponent = props => {
//   const {
//     currentUser,
//     initialMessageFailedToTransaction,
//     fetchMessagesError,
//     fetchMessagesInProgress,
//     totalMessagePages,
//     oldestMessagePageFetched,
//     fetchTransactionError,
//     history,
//     intl,
//     messages,
//     onManageDisableScrolling,
//     onSendMessage,
//     onSendReview,
//     onShowMoreMessages,
//     params,
//     scrollingDisabled,
//     sendMessageError,
//     sendMessageInProgress,
//     sendReviewError,
//     sendReviewInProgress,
//     transaction,
//     transactionRole,
//     acceptInProgress,
//     acceptSaleError,
//     declineInProgress,
//     declineSaleError,
//     onAcceptSale,
//     onDeclineSale,
//     timeSlots,
//     fetchTimeSlotsError,
//     processTransitions,
//     callSetInitialValues,
//     onInitializeCardPaymentData,
//     onFetchTransactionLineItems,
//     lineItems,
//     fetchLineItemsInProgress,
//     fetchLineItemsError,
//     cancelSaleError,
//     cancelInProgress,
//     onCancelSale,
//   } = props;

//   const currentTransaction = ensureTransaction(transaction);
//   const currentListing = ensureListing(currentTransaction.listing);
//   const isProviderRole = transactionRole === PROVIDER;
//   const isCustomerRole = transactionRole === CUSTOMER;

//   //to remove after remove old version of events
//   const isOneTimeAvailibilityEvent = currentListing.attributes.publicData.length && currentListing.attributes.publicData.availabilityType.trim() == 'onetime' && !!currentListing.attributes.publicData.date && !currentListing.attributes.publicData.termins
//   const isRegularAvailibilityEvent = currentListing.attributes.publicData.length && currentListing.attributes.publicData.availabilityType.trim() == 'regular' && !!currentListing.attributes.publicData.date && !currentListing.attributes.publicData.termins

//   const choosenTimeSlotOneTimeEvent = timeSlots && timeSlots.filter((item) => {
//     const timeSlotDate = new Date(item.attributes.start)
//     return currentListing.attributes.publicData.length ? timeSlotDate.toLocaleDateString() === new Date(currentListing.attributes.publicData.date).toLocaleDateString() : false
//   })

//   const choosenTimeSlotsNewVersion = timeSlots
//     ? timeSlots.reduce((acc, item) => {
//       const timeSlotDate = new Date(item.attributes.start)

//       const selectData = currentListing.attributes.publicData.termins ? currentListing.attributes.publicData.termins.filter(({ date }) => (new Date(date)).getTime() === timeSlotDate.getTime()) : []
//       const isTerminActive = selectData.length ? true : false

//       if (isTerminActive) {
//         item.attributes.start = selectData[0].date
//         return [...acc, item]
//       }
//       return acc
//     }, []) : []

//   let choosenTimeSlots = [] //isOneTimeAvailibilityEvent ? choosenTimeSlotOneTimeEvent : isRegularAvailibilityEvent ? timeSlots : choosenTimeSlotsNewVersion

//   if (isOneTimeAvailibilityEvent) {
//     choosenTimeSlots = choosenTimeSlotOneTimeEvent
//   } else if (isRegularAvailibilityEvent) {
//     choosenTimeSlots = timeSlots
//   }
//   else {
//     choosenTimeSlots = choosenTimeSlotsNewVersion
//   }

//   const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
//     const routes = routeConfiguration();
//     // Customize checkout page state with current listing and selected bookingDates
//     const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
//     callSetInitialValues(setInitialValues, initialValues);

//     // Clear previous Stripe errors from store if there is any
//     onInitializeCardPaymentData();

//     // Redirect to CheckoutPage
//     history.push(
//       createResourceLocatorString(
//         'CheckoutPage',
//         routes,
//         { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
//         {}
//       )
//     );
//   };

//   // If payment is pending, redirect to CheckoutPage
//   if (txIsPaymentPending(currentTransaction) && isCustomerRole) {
//     const currentBooking = ensureListing(currentTransaction.booking);

//     const initialValues = {
//       listing: currentListing,
//       // Transaction with payment pending should be passed to CheckoutPage
//       transaction: currentTransaction,
//       // Original bookingData content is not available,
//       // but it is already used since booking is created.
//       // (E.g. quantity is used when booking is created.)
//       bookingData: {},
//       bookingDates: {
//         bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
//         bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
//       },
//     };

//     redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
//   }

//   // Customer can create a booking, if the tx is in "enquiry" state.
//   const handleSubmitBookingRequest = values => {
//     const { bookingDates, ...bookingData } = values;

//     const initialValues = {
//       listing: currentListing,
//       // enquired transaction should be passed to CheckoutPage
//       transaction: currentTransaction,
//       bookingData,
//       bookingDates: {
//         bookingStart: bookingDates.startDate,
//         bookingEnd: bookingDates.endDate,
//       },
//       confirmPaymentError: null,
//     };

//     redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
//   };

//   const deletedListingTitle = intl.formatMessage({
//     id: 'TransactionPage.deletedListing',
//   });
//   const listingTitle = currentListing.attributes.deleted
//     ? deletedListingTitle
//     : currentListing.attributes.title;

//   // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
//   const isDataAvailable =
//     currentUser &&
//     currentTransaction.id &&
//     currentTransaction.id.uuid === params.id &&
//     currentTransaction.attributes.lineItems &&
//     currentTransaction.customer &&
//     currentTransaction.provider &&
//     !fetchTransactionError;

//   const isOwnSale =
//     isDataAvailable &&
//     isProviderRole &&
//     currentUser.id.uuid === currentTransaction.provider.id.uuid;
//   const isOwnOrder =
//     isDataAvailable &&
//     isCustomerRole &&
//     currentUser.id.uuid === currentTransaction.customer.id.uuid;

//   if (isDataAvailable && isProviderRole && !isOwnSale) {
//     // eslint-disable-next-line no-console
//     console.error('Tried to access a sale that was not owned by the current user');
//     return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
//   } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
//     // eslint-disable-next-line no-console
//     console.error('Tried to access an order that was not owned by the current user');
//     return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
//   }

//   const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

//   const fetchErrorMessage = isCustomerRole
//     ? 'TransactionPage.fetchOrderFailed'
//     : 'TransactionPage.fetchSaleFailed';
//   const loadingMessage = isCustomerRole
//     ? 'TransactionPage.loadingOrderData'
//     : 'TransactionPage.loadingSaleData';

//   const loadingOrFailedFetching = fetchTransactionError ? (
//     <p className={css.error}>
//       <FormattedMessage id={`${fetchErrorMessage}`} />
//     </p>
//   ) : (
//     <p className={css.loading}>
//       <FormattedMessage id={`${loadingMessage}`} />
//     </p>
//   );

//   const initialMessageFailed = !!(
//     initialMessageFailedToTransaction &&
//     currentTransaction.id &&
//     initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
//   );

//   // TransactionPanel is presentational component
//   // that currently handles showing everything inside layout's main view area.
//   const panel = isDataAvailable ? (
//     <TransactionPanel
//       className={detailsClassName}
//       currentUser={currentUser}
//       transaction={currentTransaction}
//       fetchMessagesInProgress={fetchMessagesInProgress}
//       totalMessagePages={totalMessagePages}
//       oldestMessagePageFetched={oldestMessagePageFetched}
//       messages={messages}
//       initialMessageFailed={initialMessageFailed}
//       fetchMessagesError={fetchMessagesError}
//       sendMessageInProgress={sendMessageInProgress}
//       sendMessageError={sendMessageError}
//       sendReviewInProgress={sendReviewInProgress}
//       sendReviewError={sendReviewError}
//       onManageDisableScrolling={onManageDisableScrolling}
//       onShowMoreMessages={onShowMoreMessages}
//       onSendMessage={onSendMessage}
//       onSendReview={onSendReview}
//       transactionRole={transactionRole}
//       onAcceptSale={onAcceptSale}
//       onDeclineSale={onDeclineSale}
//       acceptInProgress={acceptInProgress}
//       declineInProgress={declineInProgress}
//       acceptSaleError={acceptSaleError}
//       declineSaleError={declineSaleError}
//       nextTransitions={processTransitions}
//       onSubmitBookingRequest={handleSubmitBookingRequest}
//       timeSlots={choosenTimeSlots}
//       fetchTimeSlotsError={fetchTimeSlotsError}
//       onFetchTransactionLineItems={onFetchTransactionLineItems}
//       lineItems={lineItems}
//       fetchLineItemsInProgress={fetchLineItemsInProgress}
//       fetchLineItemsError={fetchLineItemsError}
//       onCancelSale={onCancelSale}
//       cancelSaleError={cancelSaleError}
//       cancelInProgress={cancelInProgress}
//     />
//   ) : (
//     loadingOrFailedFetching
//   );

//   return (
//     <Page
//       title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
//       scrollingDisabled={scrollingDisabled}
//     >
//       <LayoutSingleColumn>
//         <LayoutWrapperTopbar>
//           <TopbarContainer />
//         </LayoutWrapperTopbar>
//         <LayoutWrapperMain>
//           <div className={css.root}>{panel}</div>
//         </LayoutWrapperMain>
//         <LayoutWrapperFooter className={css.footer}>
//           <Footer />
//         </LayoutWrapperFooter>
//       </LayoutSingleColumn>
//     </Page>
//   );
// };

// TransactionPageComponent.defaultProps = {
//   currentUser: null,
//   fetchTransactionError: null,
//   acceptSaleError: null,
//   declineSaleError: null,
//   transaction: null,
//   fetchMessagesError: null,
//   initialMessageFailedToTransaction: null,
//   sendMessageError: null,
//   timeSlots: null,
//   fetchTimeSlotsError: null,
//   lineItems: null,
//   fetchLineItemsError: null,
// };

// const { bool, func, oneOf, shape, string, arrayOf, array, number } = PropTypes;

// TransactionPageComponent.propTypes = {
//   params: shape({ id: string }).isRequired,
//   transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
//   currentUser: propTypes.currentUser,
//   fetchTransactionError: propTypes.error,
//   acceptSaleError: propTypes.error,
//   declineSaleError: propTypes.error,
//   acceptInProgress: bool.isRequired,
//   declineInProgress: bool.isRequired,
//   onAcceptSale: func.isRequired,
//   onDeclineSale: func.isRequired,
//   scrollingDisabled: bool.isRequired,
//   transaction: propTypes.transaction,
//   fetchMessagesError: propTypes.error,
//   totalMessagePages: number.isRequired,
//   oldestMessagePageFetched: number.isRequired,
//   messages: arrayOf(propTypes.message).isRequired,
//   initialMessageFailedToTransaction: propTypes.uuid,
//   sendMessageInProgress: bool.isRequired,
//   sendMessageError: propTypes.error,
//   onShowMoreMessages: func.isRequired,
//   onSendMessage: func.isRequired,
//   timeSlots: arrayOf(propTypes.timeSlot),
//   fetchTimeSlotsError: propTypes.error,
//   callSetInitialValues: func.isRequired,
//   onInitializeCardPaymentData: func.isRequired,

//   // from withRouter
//   history: shape({
//     push: func.isRequired,
//   }).isRequired,
//   location: shape({
//     search: string,
//   }).isRequired,

//   // line items
//   lineItems: array,
//   fetchLineItemsInProgress: bool.isRequired,
//   fetchLineItemsError: propTypes.error,

//   // from injectIntl
//   intl: intlShape.isRequired,
// };

// const mapStateToProps = state => {
//   const {
//     fetchTransactionError,
//     acceptSaleError,
//     declineSaleError,
//     acceptInProgress,
//     declineInProgress,
//     transactionRef,
//     fetchMessagesInProgress,
//     fetchMessagesError,
//     totalMessagePages,
//     oldestMessagePageFetched,
//     messages,
//     initialMessageFailedToTransaction,
//     sendMessageInProgress,
//     sendMessageError,
//     sendReviewInProgress,
//     sendReviewError,
//     timeSlots,
//     fetchTimeSlotsError,
//     processTransitions,
//     lineItems,
//     fetchLineItemsInProgress,
//     fetchLineItemsError,
//     cancelSaleError,
//     cancelInProgress
//   } = state.TransactionPage;
//   const { currentUser } = state.user;

//   const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
//   const transaction = transactions.length > 0 ? transactions[0] : null;

//   return {
//     currentUser,
//     fetchTransactionError,
//     acceptSaleError,
//     declineSaleError,
//     acceptInProgress,
//     declineInProgress,
//     scrollingDisabled: isScrollingDisabled(state),
//     transaction,
//     fetchMessagesInProgress,
//     fetchMessagesError,
//     totalMessagePages,
//     oldestMessagePageFetched,
//     messages,
//     initialMessageFailedToTransaction,
//     sendMessageInProgress,
//     sendMessageError,
//     sendReviewInProgress,
//     sendReviewError,
//     timeSlots,
//     fetchTimeSlotsError,
//     processTransitions,
//     lineItems,
//     fetchLineItemsInProgress,
//     fetchLineItemsError,
//     cancelSaleError,
//     cancelInProgress
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     onAcceptSale: transactionId => dispatch(acceptSale(transactionId)),
//     onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
//     onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
//     onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
//     onManageDisableScrolling: (componentId, disableScrolling) =>
//       dispatch(manageDisableScrolling(componentId, disableScrolling)),
//     onSendReview: (role, tx, reviewRating, reviewContent) =>
//       dispatch(sendReview(role, tx, reviewRating, reviewContent)),
//     callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
//     onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
//     onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
//       dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
//     onCancelSale: transactionId => dispatch(cancelSale(transactionId)),
//   };
// };

// const TransactionPage = compose(
//   withRouter,
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   ),
//   injectIntl
// )(TransactionPageComponent);

// TransactionPage.loadData = loadData;
// TransactionPage.setInitialValues = setInitialValues;

// export default TransactionPage;

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { compose } from 'redux';
import { ReactComponent as CalendarIcon } from '../../assets/transactionPage/calendar.svg';
import { ReactComponent as InfoIcon } from '../../assets/transactionPage/info.svg';
import { ReactComponent as PinIcon } from '../../assets/transactionPage/pin.svg';
import FooterV2 from '../../components/Footer/FooterV2';
import AddressFromCoordinates from '../../components/Map/AddressFromCoordinates';
import MapWithMarker from '../../components/Map/MapWithMarker';
import { apiSdk } from '../../queries/api';
import Payment from '../ListingPage/components/bookingPanel/Payment/Payment';
import { config } from './config';
import EventSummary from './EventSummary';
import MessagesView from './MessagesView';
import RecommendedProducts from './RecommendedProducts';
import Review from './Review';

const useGetTransactionInfo = () => {
  const location = useLocation();

  const paths = [
    '/order/:transactionId/details-success',
    '/order/:transactionId/details',
    '/sale/:transactionId/details',
    '/message/:transactionId/details',
  ];

  for (const path of paths) {
    const match = matchPath(location.pathname, {
      path: path,
      exact: true,
      strict: false,
    });

    if (match) {
      const transactionId = match.params.transactionId;
      const userType = path.includes('/order/') ? 'consumer' : 'provider';
      return { transactionId, userType };
    }
  }

  return { transactionId: null, userType: null };
};

const TransactionPageComponent = props => {
  const { currentUser } = props;
  const currentUserId = currentUser && currentUser.id.uuid;

  const { transactionId, userType } = useGetTransactionInfo();

  const { data, isSuccess } = useQuery(['transaction.query', transactionId], () =>
    apiSdk.transactions.show(transactionId, {
      include: [
        'listing',
        'provider',
        'customer',
        'date',
        'chat',
        // 'review',
      ],
    })
  );
  const queryClient = useQueryClient();

  const [isPamymentFormOpen, setIsPaymentFormOpen] = useState(false);

  // const [customerData, setCustomerData] = useState(null);
  // const [priceData, setPriceData] = useState(null);
  // const [stripePaymentIntent, setStripePaymentIntent] = useState(null);

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log('data', data);
  //     const unitPrice = data.data.attributes.lineItems.find(item => item.code === 'booking')
  //       ? data.data.attributes.lineItems.find(item => item.code === 'booking').unitPrice.amount
  //       : 0;
  //     const unitPriceQuantity = data.data.attributes.lineItems.find(item => item.code === 'booking')
  //       ? data.data.attributes.lineItems.find(item => item.code === 'booking').quantity
  //       : 0;
  //     const reducedUnitPrice = data.data.attributes.lineItems.find(
  //       item => item.code === 'booking-reduced'
  //     )
  //       ? data.data.attributes.lineItems.find(item => item.code === 'booking-reduced').unitPrice
  //           .amount
  //       : 0;
  //     const reducePriceQuantity = data.data.attributes.lineItems.find(
  //       item => item.code === 'booking-reduced'
  //     )
  //       ? data.data.attributes.lineItems.find(item => item.code === 'booking-reduced').quantity
  //       : 0;
  //     const provission = data.data.attributes.lineItems.find(
  //       item => item.code === 'service-provision'
  //     )
  //       ? data.data.attributes.lineItems.find(item => item.code === 'service-provision').unitPrice
  //           .amount
  //       : 0;
  //     const provissionQuantity = data.data.attributes.lineItems.find(
  //       item => item.code === 'service-provision'
  //     )
  //       ? data.data.attributes.lineItems.find(item => item.code === 'service-provision').quantity
  //       : 0;

  //     const priceData = {
  //       unitPrice,
  //       unitPriceQuantity,
  //       reducedUnitPrice,
  //       reducePriceQuantity,
  //       provission,
  //       provissionQuantity,
  //       transactionId: data.data.id.uuid,
  //     };

  //     setStripePaymentIntent(data.data.attributes.stripePaymentIntent);

  //     setCustomerData(data.data.attributes.customerData);
  //     setPriceData(priceData);
  //   }
  // }, [isSuccess]);

  const l = useLocation();
  const history = useHistory();

  const mutationSyncPayment = useMutation(apiSdk.transactions.syncPayment, {
    onSuccess: () => {
      history.push(`/order/${transactionId}/details-success`);
      window.location.reload();
    },
  });

  useEffect(() => {
    const clientSecret = new URLSearchParams(l.search).get('payment_intent_client_secret');
    if (!clientSecret) {
      return;
    }
    mutationSyncPayment.mutate(transactionId);
  }, []);

  const mutation = useMutation(
    'transaction.cancel',
    () => apiSdk.transactions.cancel(transactionId),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(['transaction.query', transactionId]);
      },
    }
  );

  const [isMessageEditorOpen, setIsMessageEditorOpen] = useState(
    userType === 'consumer' ? false : true
  );
  const [isMapOpen, setIsMapOpen] = useState(false);

  const listingData = isSuccess && data.included.filter(item => item.type === 'listing')[0];
  const transactionData = isSuccess && data.data;
  const eventDate = isSuccess && data.included.filter(item => item.type === 'listing-date')[0];
  const processState = isSuccess && transactionData.attributes.processState;

  const users = isSuccess && data.included.filter(item => item.type === 'user');
  const providerId = isSuccess && transactionData.relationships.provider.data.id.uuid;
  const customerId = isSuccess && transactionData.relationships.customer.data.id.uuid;
  const chatId = isSuccess && transactionData.relationships.chat.data.id.uuid;

  const sts_run = listingData && listingData.attributes.stsLink;
  const customerName =
    isSuccess &&
    users.filter(item => item.id.uuid === customerId)[0].attributes.profile.displayName;

  const handleCancel = () => {
    mutation.mutate();
  };

  const {
    header,
    location,
    haveQuestionsButton,
    activeBanner,
    recommendations,
    cancelEventButton,
    review,
  } =
    isSuccess &&
    config(processState, userType === 'consumer', listingData, customerName, handleCancel, css);

  return (
    <Page scrollingDisabled={false}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            {isSuccess && (
              <div>
                <div className={css.transactionPageContainer}>
                  <div className={css.headerContainer}>
                    {header}
                    {!review && (
                      <p className={css.paragraph}>
                        <CalendarIcon style={{ marginRight: '5px' }} />
                        Kiedy:
                        {formatDate(eventDate.attributes.date)}
                      </p>
                    )}
                    {location && (
                      <p className={css.paragraph}>
                        <PinIcon /> {`Gdzie: `}
                        {!!listingData.attributes.publicData.stadium ? (
                          <span className={css.address}>
                            {listingData.attributes.publicData.stadium.split('_')[1]}
                          </span>
                        ) : (
                          <span className={css.address} onClick={() => setIsMapOpen(prev => !prev)}>
                            <AddressFromCoordinates
                              lat={listingData.attributes.geolocation.lat}
                              lng={listingData.attributes.geolocation.lng}
                              location={listingData.attributes.location}
                            />
                          </span>
                        )}
                        <br />
                        {isMapOpen && (
                          <MapWithMarker
                            lat={listingData.attributes.geolocation.lat}
                            lng={listingData.attributes.geolocation.lng}
                          />
                        )}
                        {listingData.attributes.publicData.directions && (
                          <p className={css.haveQUestions}>
                            Dodatkowe wzskazówki:{' '}
                            <span className={css.openTextFieldButton}>
                              {listingData.attributes.publicData.directions}
                            </span>
                          </p>
                        )}
                      </p>
                    )}
                    {review && <Review transactionId={transactionId} />}
                    {currentUserId === providerId && cancelEventButton}
                    {sts_run && (
                      <button
                        className={css.openTextFieldButton}
                        onClick={() =>
                          (window.location.href = `${sts_run}?imie=${currentUser.attributes.profile.firstName}&nazwisko=${currentUser.attributes.profile.lastName}&email=${currentUser.attributes.email}&go=1`)
                        }
                      >
                        Wypełnij formularz
                      </button>
                    )}
                    {haveQuestionsButton && (
                      <p className={css.haveQUestions}>
                        <InfoIcon style={{ marginRight: '5px' }} />
                        Masz pytania?
                        <button
                          className={css.openTextFieldButton}
                          onClick={() => setIsMessageEditorOpen(true)}
                        >
                          Napisz do organizatora
                        </button>
                      </p>
                    )}

                    <MessagesView
                      users={users}
                      providerId={providerId}
                      customerId={customerId}
                      chatId={chatId}
                      isMessageEditorOpen={isMessageEditorOpen}
                    />
                  </div>

                  {!!listingData.attributes.price &&
                    !!listingData.attributes.price.amount &&
                    !review && (
                      <EventSummary
                        transactionData={transactionData}
                        listingData={listingData}
                        eventDate={eventDate}
                      />
                    )}
                </div>
                {processState === 'pending-payment' && (
                  <div className={css.transactionPageContainer}>
                    <button
                      onClick={() => setIsPaymentFormOpen(true)}
                      style={{
                        background: '#3643ba',
                        borderRadius: '999px',
                        border: 'none',
                        padding: '10px 30px',
                        color: '#fff',
                        margin: '0 auto',
                      }}
                    >
                      Opłać rezerwację
                    </button>
                  </div>
                )}
                {isPamymentFormOpen && (
                  <Payment
                    // stripePaymentIntent={stripePaymentIntent}
                    // priceData={priceData}
                    // customerData={customerData}
                    data={data}
                    handleClose={() => setIsPaymentFormOpen(false)}
                    handleBack={() => setIsPaymentFormOpen(false)}
                    customStyle={{ maxWidth: '1200px', margin: '20px auto' }}
                  />
                )}
                {activeBanner && (
                  <div className={css.activebanner}>
                    <div className={css.activeinfo}>
                      <p>Podziel się sportową pasją! </p>
                      <p>
                        Oznacz nas używając <span className={css.bannerHashtag}>#decathlongo</span>{' '}
                        w Social Media
                      </p>
                    </div>
                    <div className={css.activelogo}></div>
                  </div>
                )}

                {recommendations && <RecommendedProducts listingId={listingData.id.uuid} />}
              </div>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FooterV2 />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const TransactionPage = compose(connect(mapStateToProps))(TransactionPageComponent);

export default TransactionPage;

const formatDate = d => {
  const date = new Date(d);

  const optionsDate = { weekday: 'long', day: 'numeric', month: 'long' };
  const optionsTime = { hour: '2-digit', minute: '2-digit' };

  const formattedDate = date.toLocaleDateString('pl-PL', optionsDate);
  const formattedTime = date.toLocaleTimeString('pl-PL', optionsTime);

  return (
    <>
      <p>
        Data: <span>{formattedDate}</span>
      </p>
      <p>
        Godzina: <span>{formattedTime}</span>
      </p>
    </>
  );
};
